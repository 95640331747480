import { RefObject, useMemo, useState } from 'react';
import useResizeObserver from './useResizeObserver';
import { debounce, throttle } from 'lodash';

type Options = {
  mode: 'debounce' | 'throttle';
};
const DEFAULT_OPTIONS: Options = {
  mode: 'throttle',
};

export function useElementSize<T extends HTMLElement>(
  ref: RefObject<T>,
  ms = 300,
  options = DEFAULT_OPTIONS,
) {
  const [size, setSize] = useState({
    clientWidth: 0,
    scrollWidth: 0,
    clientHeight: 0,
    scrollHeight: 0,
    boundingClientRect: new DOMRect(),
  });

  const cb = useMemo(
    () =>
      options.mode === 'throttle'
        ? throttle((target: T) => {
            const { clientWidth, scrollWidth, clientHeight, scrollHeight } =
              target;

            setSize({
              clientWidth,
              scrollWidth,
              clientHeight,
              scrollHeight,
              boundingClientRect: target.getBoundingClientRect(),
            });
          }, ms)
        : debounce((target: T) => {
            const { clientWidth, scrollWidth, clientHeight, scrollHeight } =
              target;

            setSize({
              clientWidth,
              scrollWidth,
              clientHeight,
              scrollHeight,
              boundingClientRect: target.getBoundingClientRect(),
            });
          }, ms),
    [ms, options.mode],
  );

  useResizeObserver(cb, ref);

  return size;
}
