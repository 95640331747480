import {
  Notification,
  RequestType,
  StatusType,
} from '@agilelab/plugin-wb-notification-common';
import { useLoggedUsername } from '@agilelab/plugin-wb-platform';
import { NotificationType } from '@agilelab/plugin-wb-platform-common';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import {
  QueryObserverResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { notificationApiRef } from '../../notificationApiRef';

export const ITEMS_PER_PAGE = 20;
export type NotificationDataType =
  | {
      total: number;
      notifications: Notification[];
    }
  | undefined;

type NotificationProviderContextValues = {
  page: number;
  setPage: (value: number) => void;
  notificationData: NotificationDataType;
  loadingNotificationData: boolean;
  refetchNotificationData: () => Promise<
    QueryObserverResult<NotificationDataType>
  >;
  errorNotificationData: unknown;
  kind: NotificationType | undefined;
  setKind: (value: NotificationType | undefined) => void;
  readStatus: StatusType | undefined;
  setReadStatus: (value: StatusType | undefined) => void;
  requestStatus: RequestType | undefined;
  setRequestStatus: (value: RequestType | undefined) => void;
  setRead: (id: string) => void;
  loadingRead: boolean;
  setAllAsRead: () => void;
  clearFilters: () => void;
  unreadNotificationCount: number;
};

export const NotificationProviderContext =
  createContext<NotificationProviderContextValues>(
    {} as unknown as NotificationProviderContextValues,
  );

type NotificationProps = {
  children: JSX.Element[] | JSX.Element;
};

export function useNotification() {
  return useContext(NotificationProviderContext);
}

export function NotificationProvider({
  children,
}: NotificationProps): JSX.Element {
  const notificationCatalog = useApi(notificationApiRef);
  const identityApi = useApi(identityApiRef);
  const queryClient = useQueryClient();
  const user = useLoggedUsername();
  const [page, setPage] = useState(1);
  const [kind, setKind] = useState<NotificationType | undefined>(undefined);
  const [readStatus, setReadStatus] = useState<StatusType | undefined>(
    StatusType.Unread,
  );
  const [requestStatus, setRequestStatus] = useState<RequestType | undefined>(
    undefined,
  );
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);

  const {
    data: unreadNotificationCountData,
    refetch: refreshUnreadNotificationCountData,
  } = useQuery({
    queryKey: ['countUnreadByRecipient'],
    queryFn: async () => {
      return notificationCatalog.countUnreadByRecipient(
        user!,
        await identityApi.getCredentials(),
      );
    },
    enabled: !!user,
  });

  useEffect(() => {
    if (unreadNotificationCountData)
      setUnreadNotificationCount(unreadNotificationCountData.count);
  }, [unreadNotificationCountData]);

  const { mutate: setAllAsRead } = useMutation<any, Error>({
    mutationFn: async () =>
      notificationCatalog.readAllNotifications(
        user!,
        await identityApi.getCredentials(),
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['paginatedByRecipient'] });
      refreshUnreadNotificationCountData();
    },
  });

  const { mutate: setRead, isLoading: loadingRead } = useMutation<
    any,
    Error,
    string
  >({
    mutationFn: async (id: string) =>
      notificationCatalog.readNotification(
        id,
        await identityApi.getCredentials(),
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['paginatedByRecipient'] });
      refreshUnreadNotificationCountData();
    },
  });

  useEffect(() => {
    setPage(1);
  }, [kind, readStatus, requestStatus]);

  useEffect(() => {
    setRequestStatus(undefined);
  }, [kind]);

  const {
    data: notificationData,
    isLoading: loadingNotificationData,
    error: errorNotificationData,
    refetch: refetchNotificationData,
  } = useQuery({
    queryKey: [
      'paginatedByRecipient',
      user,
      page,
      kind,
      readStatus,
      requestStatus,
    ],
    queryFn: async () => {
      return notificationCatalog.paginatedByRecipient(
        user!,
        (page - 1) * ITEMS_PER_PAGE,
        ITEMS_PER_PAGE,
        kind,
        // eslint-disable-next-line no-nested-ternary
        readStatus === undefined
          ? undefined
          : readStatus === 'read'
          ? true
          : false,
        requestStatus,
        await identityApi.getCredentials(),
      );
    },
    enabled: !!user,
  });

  const clearFilters = () => {
    setKind(undefined);
    setReadStatus(undefined);
    setRequestStatus(undefined);
  };

  const values: NotificationProviderContextValues = useMemo(
    () => ({
      page,
      setPage,
      notificationData,
      loadingNotificationData,
      errorNotificationData,
      refetchNotificationData,
      kind,
      setKind,
      readStatus,
      setReadStatus,
      requestStatus,
      setRequestStatus,
      setRead,
      loadingRead,
      setAllAsRead,
      clearFilters,
      unreadNotificationCount,
    }),
    [
      errorNotificationData,
      kind,
      loadingNotificationData,
      refetchNotificationData,
      loadingRead,
      notificationData,
      page,
      readStatus,
      requestStatus,
      setRead,
      setAllAsRead,
      unreadNotificationCount,
    ],
  );

  return (
    <NotificationProviderContext.Provider value={values}>
      {children}
    </NotificationProviderContext.Provider>
  );
}
