import { ComponentInstanceEntity, ConsumableMode } from './types';

export type ConsumableInterface = {
  id: string;
  consumable: boolean;
  components?: ConsumableInterface[];
};

/**
 * Converts a descriptor to a consumable interface.
 * @param descriptor The descriptor to convert
 * @returns The consumable interface
 */
export function toConsumableInterface(
  outputPort: ComponentInstanceEntity,
): ConsumableInterface | undefined {
  const id = outputPort.descriptor.id;
  if (!id) {
    return undefined;
  }
  const consumable = outputPort.consumable === ConsumableMode.Consumable;

  const subcomponents = outputPort.components?.flatMap(component => {
    const subcomponentId = component.data.external_id;
    if (!subcomponentId) {
      return [];
    }

    const subcomponentConsumable =
      component.data.consumable === ConsumableMode.Consumable;

    return [
      {
        id: subcomponentId,
        consumable: subcomponentConsumable,
      },
    ];
  });

  return {
    id,
    consumable,
    components: subcomponents,
  };
}

/**
 * Parses the URNs of the consumable interfaces in the descriptor.
 * The root is by default consumable if the consumable property is not set.
 * @param consumableInterface The descriptor to parse
 * @returns An array of URNs of the consumable interfaces
 */
export function parseConsumableInterfacesUrns(
  consumableInterface: ConsumableInterface,
): string[] {
  const urns = new Set<string>(
    isConsumable(consumableInterface) ? [consumableInterface.id] : [],
  );

  consumableInterface.components?.forEach(component => {
    if (isConsumable(component)) {
      urns.add(component.id);
    }
  });

  return [...urns.values()];
}

function isConsumable(component: ConsumableInterface): boolean {
  return !!component.consumable;
}
