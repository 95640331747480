import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { DataPreviewCard } from '../DataPreviewCard';
import { DependenciesCard } from '../DependenciesCard';
import { QuestionsCard } from '../QuestionsCard/QuestionsCard';
import { ReviewsCard } from '../Rating/ReviewsCard';
import { UserEntityContext } from '../../hooks/DataProductContext';
import { useAccessControlRequestTemplate } from '../OutputsCard/hooks/useAccessControlRequestTemplate';
import { AccessControlRequestDialog } from '../OutputsCard/AccessRequestDialog';
import { DataContractResults } from '../DataContractPage/legacy-page/DataContractResults';
import { DataQualityCard } from '../DataContractPage/DataQualityCard';

import {
  scaffolderApiRef,
  scaffolderTasksFinalizer,
} from '@agilelab/plugin-wb-scaffolder';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import {
  customAlertApiRef,
  CustomSnackbarError,
  CustomViewRegister,
  DrawersContextProvider,
  useAction,
  useDataPath,
  useUsername,
  WbCard,
  WbCardActionButton,
  WbCardContent,
} from '@agilelab/plugin-wb-platform';
import { AclVerb } from '@agilelab/plugin-wb-access-control-common';
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Tooltip,
  useTheme,
} from '@material-ui/core';
import { useOutputPorts } from '../../hooks/useOutputPorts';
import { TechnicalInfoCard } from '../generic/TechnicalInfoCard';
import { FlagAndScore } from '../DataProductPage/FlagAndScore/FlagAndScore';
import { ShoppableMode } from '@agilelab/plugin-wb-marketplace-common';
import { extractRightIds } from './utils';
import { PolicyViolationsOverview } from '../DataProductPage/FlagAndScore/PolicyViolationsOverview';
import { MetricResultsOverview } from '../DataProductPage/FlagAndScore/MetricResultsOverview';
import { parseUrn } from '@agilelab/plugin-wb-builder-common';
import { DataContractStatusCard } from '../DataContractPage/DataContractStatusCard';
import { DataContractStatusSummary } from '../../hooks/useDataContractStatus';
import { extractErrorMessageFromDataContractResult } from '../DataContractPage/utils';

const useStyles = makeStyles(
  theme => ({
    separator: {
      height: '25px',
      width: '1px',
      margin: '0 6px',
      backgroundColor: theme.palette.softened.primary,
    },
    customHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '4px',
    },
  }),
  { name: 'MarketplaceComponentCard' },
);

const GridItemWrapper = (children: ReactNode) => {
  const theme = useTheme();
  return (
    <Grid
      item
      style={{
        padding: `${theme.spacing(2)}px 0`,
        paddingRight: `${theme.spacing(4)}px`,
        paddingLeft: 0,
      }}
    >
      {children}
    </Grid>
  );
};

function governanceResourceFromData(data: any):
  | {
      externalId: string;
      environment: string;
      name: string;
      displayName?: string;
    }
  | undefined {
  const resourceInfo = data._computedInfo;
  const {
    kind,
    environment: systemEnvironment,
    external_id,
    display_name,
    name,
  } = resourceInfo || {};
  const isSystem = kind === 'system';
  const isComponent = kind === 'component';
  const environment = isSystem
    ? systemEnvironment?.name
    : data._system?.environment?.name;
  if ((isSystem || isComponent) && external_id && environment && name) {
    return {
      externalId: external_id,
      environment: environment,
      name: name,
      displayName: display_name ?? name,
    };
  }
  return undefined;
}

CustomViewRegister.register({
  id: 'marketplace_flag_and_score',
  function: function FlagAndScoreComponent(_: Record<string, any>) {
    const data = useDataPath('');
    const governanceResource = governanceResourceFromData(data);
    if (governanceResource) {
      return (
        <DrawersContextProvider>
          <FlagAndScore
            resourceInfo={{
              externalId: governanceResource.externalId,
              environment: governanceResource.environment,
              displayName:
                governanceResource.displayName ?? governanceResource.name,
            }}
          />
        </DrawersContextProvider>
      );
    }
    return <></>;
  },
});

CustomViewRegister.register({
  id: 'marketplace_policy_violations_overview',
  function: function PolicyViolationsOverviewComponent(
    props: Record<string, any>,
  ) {
    const data = useDataPath('');
    const governanceResource = governanceResourceFromData(data);
    if (!governanceResource) return <></>;
    return (
      <PolicyViolationsOverview
        title={props.title}
        policyIdFilter={props.policyIds ?? []}
        hideIfEmpty={props.hideIfEmpty}
        noDataMessage={props.noDataMessage}
        resource={governanceResource}
        wrapper={GridItemWrapper}
      />
    );
  },
});

CustomViewRegister.register({
  id: 'marketplace_metric_results_overview',
  function: function MetricResultsOverviewComponent(
    props: Record<string, any>,
  ) {
    const data = useDataPath('');
    const governanceResource = governanceResourceFromData(data);
    if (!governanceResource) return <></>;
    return (
      <MetricResultsOverview
        title={props.title}
        metricIdFilter={props.metricIds ?? []}
        hideIfEmpty={props.hideIfEmpty}
        noDataMessage={props.noDataMessage}
        resource={governanceResource}
        wrapper={GridItemWrapper}
      />
    );
  },
});

CustomViewRegister.register({
  id: 'data_preview',
  function: function PreviewCard(props: Record<string, any>) {
    return (
      <DataPreviewCard
        title={props.title}
        path={props.path}
        noDataLabel={props.noDataLabel}
        hideNoDataIcon={props.hideNoDataIcon}
      />
    );
  },
});

CustomViewRegister.register({
  id: 'marketplace_dependency',
  function: function DepCard() {
    return <DependenciesCard />;
  },
});

CustomViewRegister.register({
  id: 'marketplace_questions',
  function: function QuestCard() {
    return <QuestionsCard />;
  },
});

CustomViewRegister.register({
  id: 'marketplace_review',
  function: function RevCard() {
    return <ReviewsCard />;
  },
});

CustomViewRegister.register({
  id: 'marketplace_tech_card',
  function: function TechCard(props: Record<string, any>) {
    const root = useDataPath('');
    const title = props.title ?? 'Technical Information';
    const configs: string[] = props.configs;
    return (
      <TechnicalInfoCard
        title={title}
        configs={configs}
        descriptor={root ?? {}}
        wrapper={({ children }) => (
          <Grid item xs={12}>
            {children}
          </Grid>
        )}
      />
    );
  },
});

CustomViewRegister.register({
  id: 'marketplace_component_card',
  function: function OutCard(props: Record<string, any>) {
    const userEntity = useContext(UserEntityContext);
    const alertApi = useApi(customAlertApiRef);
    const scaffolderApi = useApi(scaffolderApiRef);
    const configApi = useApi(configApiRef);
    const classes = useStyles();
    const isMultipleAccessRequestEnabled = configApi.getOptionalBoolean(
      'mesh.marketplace.multipleAccessRequestSelection',
    );
    const [scaffolderTaskIds, setScaffolderTaskIds] = useState<string[]>([]);
    const [isAccessRequestDialogOpen, setIsAccessRequestDialogOpen] =
      useState(false);
    const data = useDataPath('');
    const clearSelections = useAction('clearSelection');
    const clearSelection = () => clearSelections(data.kind);
    const selectAll = useAction('selectAll');
    const grantTemplateResponse = useAccessControlRequestTemplate({
      templateType: 'grant',
    });
    const selectedOutputPorts = (data._parent._selectedComponents || []).filter(
      (s: any) =>
        (s.descriptor.kind || '').toLowerCase() ===
        (data.kind || '').toLowerCase(),
    );
    const isShoppableSystem =
      data._parent?._computedInfo?.kind === 'system' &&
      data._parent?._computedInfo?.shoppable === ShoppableMode.Shoppable;

    const ClearRequestsButton = () => (
      <Button
        variant="outlined"
        size="small"
        onClick={clearSelection}
        color="primary"
        disabled={!selectedOutputPorts || selectedOutputPorts.length === 0}
      >
        Clear
      </Button>
    );
    const RequestAccessButton = () => (
      <Tooltip title={grantTemplateResponse.error ?? ''}>
        <span>
          <WbCardActionButton
            disabled={
              !selectedOutputPorts ||
              selectedOutputPorts.length === 0 ||
              !grantTemplateResponse.template
            }
            variant="contained"
            onClick={() => setIsAccessRequestDialogOpen(true)}
            size="small"
            color="primary"
            label="Request access"
          />
        </span>
      </Tooltip>
    );

    const SelectAllButton = () => (
      <Tooltip title="Select all">
        <span>
          <WbCardActionButton
            variant="outlined"
            onClick={() => selectAll()}
            size="small"
            color="primary"
            label="Select all"
            disabled={isShoppableSystem}
          />
        </span>
      </Tooltip>
    );

    const CustomCardHeader = () => (
      <Box className={classes.customHeader}>
        {isMultipleAccessRequestEnabled && <SelectAllButton />}
        <ClearRequestsButton />
        <Box className={classes.separator} />
        <RequestAccessButton />
      </Box>
    );

    useEffect(() => {
      if (scaffolderTaskIds && scaffolderTaskIds.length > 0) {
        scaffolderTasksFinalizer(scaffolderTaskIds, scaffolderApi, alertApi);
      }
    }, [scaffolderTaskIds, scaffolderApi, alertApi]);

    return (
      <>
        {selectedOutputPorts[0] && (
          <AccessControlRequestDialog
            onRequestSent={() => clearSelection()}
            template={grantTemplateResponse.template}
            userEntity={userEntity}
            setScaffolderTaskIds={setScaffolderTaskIds}
            setIsOpen={setIsAccessRequestDialogOpen}
            isOpen={isAccessRequestDialogOpen}
            systemInstance={data._parent._computedInfo}
            selectedOutputPorts={selectedOutputPorts}
            verb={AclVerb.Grant}
            requestSource="component"
          />
        )}
        <WbCard title={props.title} actions={<CustomCardHeader />}>
          <WbCardContent>{props.children}</WbCardContent>
        </WbCard>
      </>
    );
  },
});

CustomViewRegister.register({
  id: 'marketplace_info_card',
  function: function InfoCard(props: Record<string, any>) {
    const alertApi = useApi(customAlertApiRef);
    const entity = useDataPath('');
    const isSystem = entity._computedInfo?.kind === 'system';
    const shoppableMode: ShoppableMode | null | undefined =
      entity._computedInfo?.shoppable;
    const isShoppable = isSystem
      ? shoppableMode === ShoppableMode.Shoppable
      : !!shoppableMode;
    const isSubcomponent = !!parseUrn(entity.id).subcomponent;
    // neither `SHOPPABLE` nor `HAS_SHOPPABLE_CHILD`
    const isShoppableUndefined = !entity._computedInfo?.shoppable;
    const userEntity = useUsername();
    const [isAccessRequestDialogOpen, setIsAccessRequestDialogOpen] =
      useState(false);
    const [scaffolderTaskIds, setScaffolderTaskIds] = useState<string[]>([]);
    const scaffolderApi = useApi(scaffolderApiRef);
    const { selectedOutputPorts } = useOutputPorts(
      userEntity?.username,
      extractRightIds(isSystem, isShoppable, entity),
    );

    useEffect(() => {
      if (scaffolderTaskIds && scaffolderTaskIds.length > 0) {
        scaffolderTasksFinalizer(scaffolderTaskIds, scaffolderApi, alertApi);
      }
    }, [scaffolderTaskIds, scaffolderApi, alertApi]);

    const grantTemplateResponse = useAccessControlRequestTemplate({
      templateType: 'grant',
    });

    const componentNotShoppableError =
      !isSystem && isShoppableUndefined
        ? 'The project owner has not enabled access requests for this consumable'
        : undefined;

    const systemNotShoppableError =
      isSystem && !isShoppable ? 'Access requests are disabled' : undefined;

    const systemShoppableComponentShoppableError =
      !isSystem &&
      !isShoppableUndefined &&
      entity._system?.shoppable === ShoppableMode.Shoppable
        ? `The project owner has not enabled individual access requests for this consumable. However, you can request access to its parent '${entity._system.name}', as a whole. This will grant you access to all the consumables within it, including this one.`
        : undefined;

    const noConsumableChildrenError = !entity._computedInfo?.consumable
      ? `'${entity.name}' does not have any consumable children. Request Access is disabled.`
      : undefined;

    const systemTooltip =
      !systemNotShoppableError && isSystem
        ? 'Submit an access request for all the consumables exposed by this project'
        : undefined;

    const componentTooltip =
      !componentNotShoppableError &&
      !systemShoppableComponentShoppableError &&
      !isSystem
        ? 'Submit an access request'
        : undefined;

    const RequestAccessButton = () => (
      <Tooltip
        title={
          grantTemplateResponse.error ??
          componentNotShoppableError ??
          systemNotShoppableError ??
          systemShoppableComponentShoppableError ??
          noConsumableChildrenError ??
          systemTooltip ??
          componentTooltip ??
          ''
        }
      >
        <span>
          <WbCardActionButton
            disabled={
              !grantTemplateResponse.template ||
              !!componentNotShoppableError ||
              !!systemNotShoppableError ||
              !!systemShoppableComponentShoppableError ||
              !!noConsumableChildrenError
            }
            variant="contained"
            onClick={() => setIsAccessRequestDialogOpen(true)}
            size="small"
            color="primary"
            label="Request access"
          />
        </span>
      </Tooltip>
    );
    const CustomCardHeader = () => (
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <RequestAccessButton />
      </Box>
    );
    return (
      <>
        {selectedOutputPorts?.[0] && (
          <AccessControlRequestDialog
            onRequestSent={() => {}}
            template={grantTemplateResponse.template}
            userEntity={userEntity}
            setScaffolderTaskIds={setScaffolderTaskIds}
            setIsOpen={setIsAccessRequestDialogOpen}
            isOpen={isAccessRequestDialogOpen}
            systemInstance={isSystem ? entity._computedInfo : entity._system}
            selectedOutputPorts={selectedOutputPorts}
            verb={AclVerb.Grant}
            requestSource={isSystem ? 'system' : 'component'}
          />
        )}
        <WbCard
          actions={
            isSubcomponent || (isSystem && !isShoppable) ? (
              <></>
            ) : (
              <CustomCardHeader />
            )
          }
          title={props.title}
          toolsStyle={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '8px 16px',
            wordBreak: 'break-word',
          }}
        >
          <WbCardContent>{props.children}</WbCardContent>
        </WbCard>
      </>
    );
  },
});

CustomViewRegister.register({
  id: 'marketplace_data_contract_error_panel',
  function: function InfoCard(props: Record<string, any>) {
    const data = useDataPath(
      props.path || '_dataContractStatus',
    ) as DataContractStatusSummary;

    const msg = extractErrorMessageFromDataContractResult(data);

    const formatDateTime = (date: string) => {
      const options: Intl.DateTimeFormatOptions = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
      };
      return new Intl.DateTimeFormat('en-US', options).format(new Date(date));
    };

    const getLateResultsMessage = () => {
      if (
        !data ||
        !data.isDataContractRoot ||
        data.resultScheduling?.status !== 'LATE'
      )
        return undefined;
      const lastResultTime = data.lastResult?.createdAt;
      const lastResultTimeMsg = lastResultTime ? (
        <>
          <br />
          Latest monitoring time:{' '}
          <strong>{formatDateTime(lastResultTime)}</strong>
        </>
      ) : null;
      return (
        <>
          A monitoring report was due from the data contract guardian by{' '}
          <strong>
            {formatDateTime(data.resultScheduling.expectedResultTime)}
          </strong>{' '}
          to assess this data contract's compliance, yet it has not been
          received.
          {lastResultTimeMsg}
        </>
      );
    };

    const lateResultsMsg = getLateResultsMessage();

    return msg || lateResultsMsg ? (
      <Box display="flex" flexDirection="column" style={{ gap: '8px' }}>
        {msg && (
          <CustomSnackbarError
            variant="compact"
            style={{ margin: 0, borderRadius: 4 }}
            message={{
              title: 'Error',
              content: msg,
              type: 'error',
            }}
          />
        )}
        {lateResultsMsg && (
          <CustomSnackbarError
            variant="compact"
            style={{ margin: 0, borderRadius: 4 }}
            message={{
              title: 'Late data contract monitoring results',
              content: lateResultsMsg,
              type: 'warning',
            }}
          />
        )}
      </Box>
    ) : (
      <></>
    );
  },
});

// new data contract result component
CustomViewRegister.register({
  id: 'marketplace_data_contract_policy_status',
  function: function InfoCard(props: Record<string, any>) {
    const data = useDataPath(
      props.path || '_dataContractStatus',
    ) as DataContractStatusSummary;

    if (
      !data ||
      !(data.lastResult?.details.parsingOutcome === 'success') ||
      !data.lastResult.details.targetValidations
    )
      return <></>;
    return (
      <DataContractStatusCard
        validations={data.lastResult.details.targetValidations}
      />
    );
  },
});

// TODO used in the old demo data contract page, deprecate
CustomViewRegister.register({
  id: 'marketplace_data_contract_result',
  function: function InfoCard(props: Record<string, any>) {
    const data = useDataPath(props.path || '');
    return <DataContractResults outputPort={data} />;
  },
});

CustomViewRegister.register({
  id: 'marketplace_data_contract_quality',
  function: function InfoCard(props: Record<string, any>) {
    const data = useDataPath(props.path || '');
    return <DataQualityCard outputPortEntity={data} />;
  },
});
