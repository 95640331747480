import React, { ReactNode } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Link } from '@backstage/core-components';
import { getTruncateMaxLinesStyle } from '../../styles';

type WbBreadcrumbProps = {
  children: ReactNode;
  to?: string;
};

const useStyles = makeStyles(
  theme => ({
    root: {
      ...getTruncateMaxLinesStyle(1, true),
    },
    link: {
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  }),
  { name: 'WbBreadcrumb' },
);

export const WbBreadcrumb = ({ to, children }: WbBreadcrumbProps) => {
  const classes = useStyles();

  if (to !== undefined)
    return (
      <Link
        color="inherit"
        to={to}
        className={clsx(classes.root, classes.link)}
      >
        {children}
      </Link>
    );

  return <Box className={classes.root}>{children}</Box>;
};
