import React from 'react';
import { Editor } from '../common/Editor/Editor';
import { WbCard, WbCardContent } from '@agilelab/plugin-wb-platform';

interface PolicyWittyGovernanceAgentEditorProps {
  onUpdate?: (code?: string) => void;
  editable?: boolean;
  policyContent: string;
  saving?: boolean;
  handleSave?: () => void;
  showSaveButton?: boolean;
}

export const PolicyWittyGovernanceAgentEditor: React.FC<
  PolicyWittyGovernanceAgentEditorProps
> = props => {
  return (
    <WbCard title="Policy Content">
      <WbCardContent>
        <Editor
          id="policy-content-editor"
          content={props.policyContent}
          onUpdate={props.onUpdate}
          editable={props.editable}
          saving={props.saving}
          handleSave={props.handleSave}
          showSaveButton={props.showSaveButton}
          parser="go"
        />
      </WbCardContent>
    </WbCard>
  );
};
