import {
  ProviderFilters,
  SearchFilter,
  WbTableFilters,
} from '@agilelab/plugin-wb-platform';
import React, { useCallback } from 'react';
import useDataContractContext from './DataContractProvider';
import { SystemFilter } from '../ConsumableProvider/ConsumablesFilters';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { generateURNByKind } from '@agilelab/plugin-wb-builder-common';

export interface DataContractFilters extends ProviderFilters {
  system?: { name: string; urn: string }[];
}

enum AvailableDataContractFilters {
  search = 'search',
  system = 'system',
}

export const DataContractFiltersSection = () => {
  const { filters, changeFilters, resetFilters, templateKind } =
    useDataContractContext();

  const catalogApi = useApi(catalogApiRef);

  const fetchAvailableSystems = useCallback(async () => {
    return catalogApi
      .getEntities({ filter: { kind: 'system' } })
      .then(response =>
        response.items.map(item => {
          const urn = generateURNByKind(item.metadata.name, item.kind);
          return { name: item.metadata.name, urn };
        }),
      );
  }, [catalogApi]);

  const filterElement = (key: string) => {
    if (key === AvailableDataContractFilters.search)
      return (
        <SearchFilter
          key={key}
          value={filters.search ?? ''}
          changeFilters={changeFilters}
        />
      );

    if (
      key === AvailableDataContractFilters.system &&
      !templateKind?.filter?.system
    )
      return (
        <SystemFilter
          key={key}
          value={filters.system ?? []}
          changeFilters={changeFilters}
          getOptions={fetchAvailableSystems}
        />
      );

    return <></>;
  };

  return (
    <WbTableFilters onClear={resetFilters}>
      {templateKind?.userFilters?.map(filter => filterElement(filter))}
    </WbTableFilters>
  );
};
