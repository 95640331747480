import React, { useRef, useState } from 'react';
import { useEditorPageContext } from '../../context/useEditorPageContext';
import { Box, Typography, makeStyles, useTheme } from '@material-ui/core';
import {
  WbSearch,
  WbTreeLoader,
  WbTree,
  useElementSize,
} from '@agilelab/plugin-wb-platform';
import { EntityWithIcon } from '../../types';
import { Entity } from '@backstage/catalog-model';
import { EntityCard } from './EntityCard';

const useStyles = makeStyles(theme => ({
  selected: { border: 'none', background: theme.palette.bkg.primary },
}));

interface Props {
  entities: EntityWithIcon[];
  entity: Entity;
}

const Structure: React.FC<Props> = ({ entity, entities }) => {
  const classes = useStyles();
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const { clientWidth } = useElementSize(containerRef);

  const extraSmall = clientWidth < theme.breakpoints.values.sm / 2;

  return (
    <div ref={containerRef}>
      <WbTree
        gap={8}
        root={
          <EntityCard
            disabled={entity.metadata.name !== entities[0].metadata.name}
            className={
              entity.metadata.name === entities[0].metadata.name
                ? classes.selected
                : ''
            }
            entity={entities[0]}
            short={extraSmall}
          />
        }
        children={entities?.slice(1, entities?.length).map(relatedEntity => (
          <EntityCard
            short={extraSmall}
            disabled={
              entity.metadata.name !== entities[0].metadata.name &&
              entity.metadata.name !== relatedEntity.metadata.name
            }
            entity={relatedEntity}
            className={
              entity.metadata.name === relatedEntity.metadata.name
                ? classes.selected
                : ''
            }
          />
        ))}
      />
    </div>
  );
};

export const StructureCard: React.FC = () => {
  const { entitiesState, parent, entity } = useEditorPageContext();
  const [searchValue, setSearchValue] = useState<string | undefined>();

  return (
    <Box
      style={{
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <Box
        style={{
          marginBottom: '16px',
          height: '30px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography style={{ fontWeight: 500 }}>Structure</Typography>
      </Box>

      <Box style={{ marginBottom: '8px' }}>
        {!parent && (
          <WbSearch
            onChange={value => {
              setSearchValue(value);
            }}
          />
        )}
      </Box>

      <>
        {entitiesState?.loading ? (
          <WbTreeLoader />
        ) : (
          <Structure
            entity={entity}
            entities={(entitiesState?.value || []).filter((e, index) => {
              if (index === 0) return true;

              return searchValue
                ? new RegExp(searchValue, 'ig').test(
                    (e.spec?.mesh as any)?.name,
                  )
                : true;
            })}
          />
        )}
      </>
    </Box>
  );
};
