import React from 'react';
import { Box, Tab, Tabs, Theme, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { WbTabProps } from './WbTab';

const useStyles = makeStyles(
  (theme: Theme) => ({
    wrapper: {
      padding: '12px 16px',
    },
    container: {
      display: 'flex',
      borderRadius: 24,
      background: theme.palette.bkg.primary,
    },
    tab: {
      padding: '6px 12px',
      position: 'relative',
      fontWeight: 500,
      cursor: 'pointer',
    },
    divider: {
      '&::after': {
        content: '""',
        height: '50%',
        width: 2,
        background: theme.palette.blueGrey[200],
        display: 'inline-block',
        marginLeft: '11.5px',
        position: 'absolute',
        top: '25%',
      },
    },
    defaultTab: {
      color: theme.palette.text.primary,
      opacity: 1,
      fontWeight: 500,
      margin: '0 8px',
      minWidth: 0,
      padding: 0,
      letterSpacing: '0.009rem',
      fontSize: '1.125rem',
      '&:first-child': {
        marginLeft: 0,
      },
    },
    tabRoot: {
      '&:hover': {
        backgroundColor: 'transparent',
        color: theme.palette.primary.main,
      },
    },
    selected: {
      color: theme.palette.primary.main,
    },
  }),
  { name: 'WbTabs' },
);

interface WbTabsProps extends WbTabProps {
  selected?: boolean;
  action?: () => void;
}

interface Props {
  tabs: Array<WbTabsProps>;
  wrapperClassName?: string;
  containerClassName?: string;
  selectedClassName?: string;
  variant?: 'switch' | 'default';
  handleChange?: (_: React.ChangeEvent<{}>, newValue: number) => void;
  selectedTab?: any;
  tabStyle?: React.CSSProperties;
  scrollable?: boolean;
}

export const WbTabs: React.FC<Props> = ({
  tabs,
  containerClassName,
  selectedClassName,
  wrapperClassName,
  variant = 'switch',
  handleChange,
  selectedTab,
  tabStyle,
  scrollable = false,
}) => {
  const classes = useStyles();
  return variant === 'switch' ? (
    <Box className={clsx(classes.wrapper, wrapperClassName)}>
      <Box className={clsx(classes.container, containerClassName)}>
        {tabs.map(({ name, action, selected }, index) => {
          const hasDivider = index >= 0 && index < tabs.length - 1;
          return (
            <Box
              className={clsx(
                classes.tab,
                hasDivider && classes.divider,
                selected && classes.selected,
                selected && selectedClassName,
              )}
              onClick={action}
              key={index}
            >
              {name}
            </Box>
          );
        })}
      </Box>
    </Box>
  ) : (
    <Tabs
      selectionFollowsFocus
      indicatorColor="primary"
      aria-label="tabs"
      onChange={handleChange}
      value={selectedTab}
      style={tabStyle}
      variant={scrollable ? 'scrollable' : 'standard'}
      scrollButtons="off"
    >
      {tabs.map((tab, index) => (
        <Tab
          data-testid={`header-tab-${index}`}
          label={tab.name}
          key={tab.name}
          value={index}
          className={classes.defaultTab}
          classes={{ selected: classes.selected, root: classes.tabRoot }}
        />
      ))}
    </Tabs>
  );
};
