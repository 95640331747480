import React, { useState } from 'react';
import {
  WbCard,
  WbCardContent,
  WbTabs,
  WbWidget,
} from '@agilelab/plugin-wb-platform';
import { Box } from '@material-ui/core';
import { GovernanceTestOverviewPolicyInfo } from './GovernanceTestOverviewPolicyInfo';
import { PolicyCueScriptEditor } from '../../../PolicyDetail';
import { Engine, Policy } from '@agilelab/plugin-wb-governance-common';
import { GovernanceTestOverviewPolicyDetails } from './GovernanceTestOverviewPolicyDetails';
import { PolicyWittyGovernanceAgentEditor } from '../../../PolicyDetail/PolicyWittyGovernanceAgentEditor';

interface Props {
  policy: Policy;
  infoContent?: JSX.Element;
  test: {
    errors?: string[];
    content: string;
    name: string;
  };
}

enum Tab {
  Result,
  Entity,
}

export const GovernanceTestOverviewPolicyControlPanel: React.FC<
  Props
> = props => {
  const [selectedTab, setSelectedTab] = useState<Tab>(Tab.Result);
  return (
    <WbCard
      title="Overview"
      toolsComponents={
        <WbTabs
          tabs={[
            {
              name: 'Result',
              action: () => {
                setSelectedTab(Tab.Result);
              },
              selected: selectedTab === Tab.Result,
            },
            {
              name: 'Policy',
              action: () => {
                setSelectedTab(Tab.Entity);
              },
              selected: selectedTab === Tab.Entity,
            },
          ]}
        />
      }
    >
      <WbCardContent>
        {selectedTab === Tab.Result ? (
          <Box
            style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
          >
            <WbWidget title="Info">
              <WbCardContent>{props.infoContent}</WbCardContent>
            </WbWidget>

            <GovernanceTestOverviewPolicyDetails
              {...props}
              test={{
                errors: props.test.errors || [],
                content: props.test.content,
                name: props.test.name,
              }}
            />
          </Box>
        ) : (
          <>
            <GovernanceTestOverviewPolicyInfo {...props} />

            {props.policy.engine === Engine.Cue && (
              <Box mt={2}>
                <PolicyCueScriptEditor
                  cueScript={props.policy.cueSpecific.cueScript}
                  editable={false}
                />
              </Box>
            )}
            {props.policy.engine === Engine.GovernanceAgent && (
              <Box mt={2}>
                <PolicyWittyGovernanceAgentEditor
                  policyContent={props.policy.governanceAgentSpec.prompt}
                  editable={false}
                />
              </Box>
            )}
          </>
        )}
      </WbCardContent>
    </WbCard>
  );
};
