/* eslint-disable no-cond-assign */
/* eslint-disable no-param-reassign */
import { ErrorInfo } from '@agilelab/plugin-wb-governance-common';
import { ConfigApi } from '@backstage/core-plugin-api';

const DEFAULT_WITTY_ENGINE_ENDPOINT = '/v1/evaluate';

type NamedAndVersioned = {
  name: string;
  version: string | number;
};

export const generateHeaderTitle = (
  entity: NamedAndVersioned | undefined,
  defaultValue: string,
  suffix: string = '',
) => {
  return entity ? `${entity.name} v${entity.version}${suffix}` : defaultValue;
};

const extractErrorMessage = (text: string) => {
  return text.replace(new RegExp(`(?:\\s)\\w*:\\d*:\\d*`, 'g'), '');
};

const extractErrorRow = (error: string, regx: RegExp) => {
  const errorRows: Array<number> = [];
  let item: RegExpExecArray | null;

  while ((item = regx.exec(error))) {
    errorRows.push(+item[1]);
  }
  return errorRows;
};

export const extractGovernanceErrorInfo = ({
  policyId,
  error,
  regxPolicy,
  regxDescriptor,
}: {
  policyId: string;
  error: string;
  regxPolicy?: RegExp;
  regxDescriptor?: RegExp;
}) => {
  if (!regxPolicy) {
    regxPolicy = new RegExp(`(?:\\s)${policyId}:(\\d*)`, 'g');
  }
  if (!regxDescriptor) {
    regxDescriptor = new RegExp(`(?:\\s)descriptor:(\\d*)`, 'g');
  }

  const errorInfo: ErrorInfo = {
    message: extractErrorMessage(error),
    policyErrorRows: extractErrorRow(error, regxPolicy),
    descriptorErrorRows: extractErrorRow(error, regxDescriptor),
  };

  return errorInfo;
};

export const debounceFunction = (fn: Function, ms = 500) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return (...args: any[]) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn(args), ms);
  };
};

export function roundWithoutApprossimation(
  precision: number,
  n?: number,
): string {
  if (n !== null && n !== undefined) {
    const factor = Math.pow(10, precision);
    return String(Math.floor(n * factor) / factor);
  }
  return '-';
}

export function removeEndpointValidationUrl(
  url: string,
  config: ConfigApi,
): string {
  const agentEndpoint =
    config.getOptionalString('mesh.governance.agent.cgp.endpoint') ??
    DEFAULT_WITTY_ENGINE_ENDPOINT;
  let viewUrl = url;
  if (viewUrl?.endsWith(agentEndpoint))
    viewUrl = viewUrl.slice(0, -agentEndpoint.length);

  return viewUrl;
}

export function addEndpointValidationUrl(
  url: string,
  config: ConfigApi,
): string {
  const agentEndpoint =
    config.getOptionalString('mesh.governance.agent.cgp.endpoint') ??
    DEFAULT_WITTY_ENGINE_ENDPOINT;
  let viewUrl = url;
  if (!viewUrl?.endsWith(agentEndpoint)) viewUrl = viewUrl + agentEndpoint;

  return viewUrl;
}
