import { Box, TextFieldProps } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import React, { useEffect, useRef, useState } from 'react';
import { ClearButton } from './ClearButton';
import { SelectIcon } from './SelectIcon';
import { WbCheckboxMenuItem } from './WbCheckboxMenuItem';
import { WbChip } from './WbChip';
import { WbTextField } from './WbTextField';

type MultiSelectConditionalProps =
  | {
      options: string[];
      getOptionLabel?: (value: string) => string;
      children?: never;
    }
  | {
      options?: never;
      getOptionLabel?: never;
      children: React.ReactNode;
    };

type MultiSelectSharedProps = {
  value: string[];
  onChange: (event: React.ChangeEvent<{ value: string[] }>) => void;
};

type Props = Omit<TextFieldProps, 'select' | 'onChange' | 'value'> &
  MultiSelectSharedProps &
  MultiSelectConditionalProps & {
    clearable?: boolean;
    limitSelectedDisplay?: number;
    expandChipsVertically?: boolean;
  };

export const WbMultiSelect = React.forwardRef(
  (
    {
      clearable,
      limitSelectedDisplay,
      expandChipsVertically = true,
      getOptionLabel,
      ...props
    }: Props,
    ref?: React.Ref<any>,
  ) => {
    const [internalValue, setInternalValue] = useState(props.value || []);
    useEffect(() => {
      setInternalValue(props.value || []);
    }, [props.value]);
    const inputRef = useRef<HTMLInputElement | null>(null);
    return (
      <WbTextField
        {...props}
        ref={ref}
        value={internalValue}
        onChange={e => {
          const event = e as unknown as React.ChangeEvent<{ value: string[] }>;
          const value = event.target.value;
          setInternalValue(value);
          props.onChange(event);
        }}
        select
        SelectProps={{
          inputRef: inputRef,
          IconComponent: SelectIcon,
          multiple: true,
          renderValue: selected => (
            <Box
              style={{
                display: 'flex',
                flexWrap: expandChipsVertically ? 'wrap' : undefined,
                ...(props.disabled
                  ? { opacity: 0.5, pointerEvents: 'none' }
                  : {}),
              }}
            >
              {(limitSelectedDisplay
                ? (selected as string[])?.slice(0, limitSelectedDisplay)
                : (selected as string[])
              )?.map(value => {
                return (
                  <WbChip
                    key={value}
                    label={getOptionLabel?.(value) || value}
                    onDelete={e => {
                      const event = e as unknown as React.ChangeEvent<{
                        value: string[];
                      }>;
                      const newValue = internalValue.filter(v => v !== value);
                      setInternalValue(newValue);
                      event.target.value = newValue;
                      props.onChange(event);
                      inputRef?.current?.focus();
                    }}
                    deleteIcon={
                      <ClearIcon
                        onMouseDown={event => event.stopPropagation()}
                      />
                    }
                  />
                );
              })}
              {limitSelectedDisplay &&
                (selected as string[]).length > limitSelectedDisplay && (
                  <WbChip
                    key={`wbchip-${
                      (selected as string[]).length - limitSelectedDisplay
                    }`}
                    label={`+${
                      (selected as string[]).length - limitSelectedDisplay
                    }`}
                  />
                )}
            </Box>
          ),
          ...props.SelectProps,
        }}
        InputProps={{
          endAdornment: clearable && !props.disabled && (
            <ClearButton
              style={{
                marginRight: '25px',
              }}
              onClear={e => {
                const event = e as unknown as React.ChangeEvent<{
                  value: string[];
                }>;
                setInternalValue([]);
                event.target.value = [];
                if (props.onChange) props.onChange(event);
              }}
            />
          ),
          ...props.InputProps,
        }}
      >
        {props?.options?.map((option, i) => (
          <WbCheckboxMenuItem
            key={i}
            value={option}
            checked={props.value?.includes(option)}
            label={getOptionLabel ? getOptionLabel(option) : option}
          />
        ))}
        {props?.children}
      </WbTextField>
    );
  },
);
