import React, { useEffect, useState } from 'react';
import {
  ACL,
  customAlertApiRef,
  useEnvironmentsContext,
  useUserProfile,
  WbCard,
  WbCardContent,
  WbDockableGroup,
  WbDockablePanel,
  WbDockableResizeHandle,
  WbEnvironmentSelector,
} from '@agilelab/plugin-wb-platform';
import { SystemList } from './SystemList';
import { AccessControlList } from './AccessControlListByDp';
import useAsync from 'react-use/lib/useAsync';
import { useApi } from '@backstage/core-plugin-api';
import { accessControlListApiRef } from '@agilelab/plugin-wb-access-control';
import { ACLSystemOwned } from '@agilelab/plugin-wb-access-control-common';

export interface SystemItem {
  id: number;
  domain: string;
  systemVersion: string;
  displayName: string;
  kind: string;
}

export interface ACLByDP extends ACL {
  id: number;
  refs?: { user?: boolean; groups?: string[] };
  /**
   * Consumable type (e.g., table, file, event)
   */
  type: string;
  parent?: { id: number; displayName: string };
}

export const DataAccessTab = () => {
  const accessControlListClient = useApi(accessControlListApiRef);
  const alertApi = useApi(customAlertApiRef);
  const { environment } = useEnvironmentsContext();
  const [selectedRow, setSelectedRow] = useState<SystemItem | undefined>(
    undefined,
  );
  const [systemSelected, setSystemSelected] = useState<ACLSystemOwned>();
  const [systems, setSystems] = useState<SystemItem[]>([]);
  const { backstageIdentity } = useUserProfile();

  const { value } = useAsync(async () => {
    if (backstageIdentity) {
      try {
        return (
          (await accessControlListClient.getACLSystemsOwnedByRefs(
            backstageIdentity.ownershipEntityRefs.map(ref =>
              ref.replace('default/', ''),
            ),
            environment.name,
          )) ?? []
        );
      } catch (error) {
        alertApi.post({
          error,
          severity: 'error',
        });
      }
    }
    return undefined;
  }, [environment, backstageIdentity]);

  useEffect(() => {
    setSystems(
      value?.map(v => ({
        displayName: v.systemDisplayName,
        id: v.systemId,
        kind: 'Data Product',
        domain: v.domain,
        systemVersion: v.systemVersion,
      })) ?? [],
    );
  }, [value]);

  useEffect(() => {
    const aclSystemOwned = value?.find(dp => dp.systemId === selectedRow?.id);
    setSystemSelected(aclSystemOwned);
  }, [selectedRow, value]);

  return (
    <WbCard title="Your Data Access List" actions={<WbEnvironmentSelector />}>
      <WbCardContent>
        <WbDockableGroup direction="horizontal">
          <WbDockablePanel defaultSize={30}>
            <SystemList setSelectedRow={setSelectedRow} systems={systems} />
          </WbDockablePanel>
          <WbDockableResizeHandle direction="horizontal" />
          <WbDockablePanel>
            <AccessControlList selectedRow={systemSelected} />
          </WbDockablePanel>
        </WbDockableGroup>
      </WbCardContent>
    </WbCard>
  );
};
