import { Grid } from '@material-ui/core';
import React, { useMemo } from 'react';
import { Engine, Policy } from '@agilelab/plugin-wb-governance-common';
import {
  WbDockableGroup,
  WbDockablePanel,
  WbDockableResizeHandle,
} from '@agilelab/plugin-wb-platform';
import { useEditorScroll } from '../../../../hooks/useEditorScroll';
import { extractGovernanceErrorInfo } from '../../../../utils/governanceUtils';
import { Editor } from '../../../common/Editor/Editor';
import { ErrorBox } from '../../../common/ErrorBox/ErrorBox';

interface Props {
  policy: Policy;
  test: {
    errors?: string[];
    content: string;
    name: string;
  };
}

export const GovernanceTestOverviewPolicyDetails: React.FC<Props> = props => {
  const { test, policy } = props;
  const [currentErrorIndex, setCurrentErrorIndex] = React.useState<number>(0);
  const handleScroll = useEditorScroll('cm-scroller');

  const errors = useMemo(
    () =>
      (test?.errors || []).map(error =>
        extractGovernanceErrorInfo({ policyId: policy.policyId, error }),
      ),
    [policy.policyId, test?.errors],
  );

  const currentError = useMemo(
    () => errors?.at(currentErrorIndex),
    [currentErrorIndex, errors],
  );

  const handleSwitch = (index: number) => {
    setCurrentErrorIndex(index);
  };

  React.useEffect(() => {
    const cueRow = currentError?.policyErrorRows?.at(0);
    const descriptorRow = currentError?.descriptorErrorRows?.at(0);

    if (`policy-${props.policy.groupId}` && cueRow) {
      handleScroll(cueRow, `policy-${props.policy.groupId}`);
    }
    if (`descriptor-${props.policy.groupId}` && descriptorRow) {
      handleScroll(descriptorRow, `descriptor-${props.policy.groupId}`);
    }
  }, [
    currentError?.descriptorErrorRows,
    currentError?.policyErrorRows,
    handleScroll,
    props.policy.groupId,
  ]);

  const hasTwoPanels = useMemo(
    () =>
      Boolean(
        (policy.engine === Engine.Cue ||
          policy.engine === Engine.GovernanceAgent) &&
          test.content,
      ),
    [policy.engine, test.content],
  );

  return (
    <Grid container style={{ flex: 1 }}>
      {!!currentError && (
        <Grid item xs={12}>
          <ErrorBox
            policyEditorId={`policy-${props.policy.groupId}`}
            descriptorEditorId={`descriptor-${props.policy.groupId}`}
            headerTitle="Error Info"
            error={currentError}
            handleScroll={handleScroll}
            navigation={{
              current: currentErrorIndex,
              total: errors?.length || 0,
              handleSwitch: handleSwitch,
            }}
          />
        </Grid>
      )}

      {hasTwoPanels ? (
        <Grid item lg={12}>
          <WbDockableGroup direction="horizontal">
            {(policy.engine === Engine.Cue && (
              <WbDockablePanel collapsible>
                <Editor
                  id={`policy-${policy.groupId}`}
                  name="Cue Script"
                  content={policy.cueSpecific.cueScript}
                  editable={false}
                  highlightLines={currentError?.policyErrorRows}
                  parser="go"
                />
              </WbDockablePanel>
            )) ||
              (policy.engine === Engine.GovernanceAgent && (
                <WbDockablePanel collapsible>
                  <Editor
                    id={`policy-${policy.groupId}`}
                    name="Policy Content"
                    content={policy.governanceAgentSpec.prompt}
                    editable={false}
                    highlightLines={currentError?.policyErrorRows}
                    parser="go"
                  />
                </WbDockablePanel>
              ))}

            <WbDockableResizeHandle direction="horizontal" />

            <WbDockablePanel collapsible>
              <Editor
                id={`descriptor-${policy.groupId}`}
                name="Descriptor"
                editable={false}
                content={test.content}
                highlightLines={currentError?.descriptorErrorRows}
              />
            </WbDockablePanel>
          </WbDockableGroup>
        </Grid>
      ) : (
        <>
          {(policy.engine === Engine.Cue && (
            <Grid item xs={12} lg={6}>
              <Editor
                id={`policy-${policy.groupId}`}
                name="Cue Script"
                content={policy.cueSpecific.cueScript}
                editable={false}
                highlightLines={currentError?.policyErrorRows}
                parser="go"
              />
            </Grid>
          )) ||
            (policy.engine === Engine.GovernanceAgent && (
              <Grid item xs={12} lg={6}>
                <Editor
                  id={`policy-${policy.groupId}`}
                  name="Policy Content"
                  content={policy.governanceAgentSpec.prompt}
                  editable={false}
                  highlightLines={currentError?.policyErrorRows}
                  parser="go"
                />
              </Grid>
            ))}
          {(test.content && (
            <Grid item xs={12} lg={policy.engine === Engine.Cue ? 6 : 12}>
              <Editor
                id={`descriptor-${policy.groupId}`}
                name="Descriptor"
                editable={false}
                content={test.content}
                highlightLines={currentError?.descriptorErrorRows}
              />
            </Grid>
          )) || (
            <Grid
              item
              xs={12}
              lg={policy.engine === Engine.GovernanceAgent ? 6 : 12}
            >
              <Editor
                id={`descriptor-${policy.groupId}`}
                name="Descriptor"
                editable={false}
                content={test.content}
                highlightLines={currentError?.descriptorErrorRows}
              />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};
