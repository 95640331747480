import { InformativeNotification } from '@agilelab/plugin-wb-notification-common';
import { Avatar, CodeSnippet } from '@backstage/core-components';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { Box, Typography, useTheme } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NotificationsIcon from '@material-ui/icons/Notifications';
import DOMPurify from 'dompurify';
import { DateTime } from 'luxon';
import React, { useCallback } from 'react';
import { MarkAsReadButton } from './MarkAsReadButton';
import {
  NotificationAccordion,
  NotificationAccordionDetails,
  NotificationAccordionSummary,
} from './NotificationAccordion';
import { useNotificationStyle } from './NotificationsPageStyle';
import logo from './witboost_logo.svg';

const RenderHTML = (props: { HTML: string }) => {
  if (props.HTML)
    return (
      <div
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.HTML) }}
      />
    );
  return <></>;
};

const RenderJSON = (props: { JSON: string }) => {
  const theme = useTheme();
  if (props.JSON)
    try {
      return (
        <CodeSnippet
          text={JSON.stringify(props.JSON, null, 2)}
          language="json"
          customStyle={{
            padding: '16px',
            margin: '0',
            maxHeight: '200px',
            backgroundColor: theme.palette.background.default,
          }}
        />
      );
    } catch (error) {
      return <Typography color="error">Cannot Parse JSON Response</Typography>;
    }
  return <></>;
};

export const InformativeNotificationAccordion = (props: {
  notification: InformativeNotification;
  onAccordionExpansionsChange: (value: string | boolean) => void;
  expandedAccordion: string | boolean;
}) => {
  const { notification, onAccordionExpansionsChange, expandedAccordion } =
    props;
  const classes = useNotificationStyle();
  const theme = useTheme();
  const configApi = useApi(configApiRef);

  const expandable =
    !!notification.notification_request.html ||
    !!notification.notification_request.json;

  const onToggle = useCallback(
    (_event: object, expanded: boolean) => {
      if (expandable && expanded) {
        onAccordionExpansionsChange(notification.id.toString());
      } else {
        onAccordionExpansionsChange(false);
      }
    },
    [expandable, notification.id, onAccordionExpansionsChange],
  );

  return (
    <NotificationAccordion
      onChange={onToggle}
      expanded={expandedAccordion === notification.id.toString()}
    >
      <NotificationAccordionSummary
        expandIcon={expandable ? <ExpandMoreIcon /> : null}
        style={{ cursor: expandable ? 'pointer' : 'default' }}
      >
        <div className={classes.summary}>
          <Box>
            <div className={classes.summaryHeader}>
              <Typography className={classes.notificationTime}>
                {DateTime.fromISO(notification.created_at).toFormat(
                  'dd/MM/yyyy HH:mm',
                )}
              </Typography>
              <NotificationsIcon fontSize="small" color="primary" />
            </div>
            <div className={classes.summaryDescription}>
              <Avatar
                displayName={
                  notification.sender ??
                  configApi.getOptionalString('app.title') ??
                  'Witboost'
                }
                picture={logo}
                customStyles={{
                  width: theme.spacing(7),
                  height: theme.spacing(7),
                }}
              />
              <div className={classes.summaryDescriptionText}>
                <Typography variant="body1">
                  {notification.notification_request.text}
                </Typography>
              </div>
            </div>
          </Box>
          <Box alignSelf="center">
            <MarkAsReadButton notification={notification} />
          </Box>
        </div>
      </NotificationAccordionSummary>

      <NotificationAccordionDetails style={{ flexDirection: 'column' }}>
        <RenderHTML HTML={notification.notification_request.html} />
        <div style={{ maxWidth: '100%' }}>
          <RenderJSON JSON={notification.notification_request.json} />
        </div>
      </NotificationAccordionDetails>
    </NotificationAccordion>
  );
};
