import { useMemo } from 'react';
import { Column, TemplateEntity } from '@agilelab/plugin-wb-platform';

export const useColumns = (
  defaultColumns: Map<string, string>,
  templateKind?: TemplateEntity,
): Column[] => {
  return useMemo(() => {
    if (templateKind?.columns) {
      return templateKind?.columns.map(c => ({
        name: c.name,
        path: 'path' in c ? c.path : defaultColumns.get(c.value)!,
      }));
    }
    return Array.from(defaultColumns, ([name, value]) => ({
      name,
      path: value,
    }));
  }, [defaultColumns, templateKind?.columns]);
};
