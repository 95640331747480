import { ConsumableMode } from '../types';

export const GET_OUTPUT_PORTS_BY_INSTANCE_ID = `
query GetOutputPortsByInstanceId($id: bigint, $identities: [String!]) {
  instances: marketplace_instances(
    where: { _and: [
      { kind: {_eq: "component"} },
      { consumable: { _in: ["CONSUMABLE", "HAS_CONSUMABLE_CHILD"] } }
      { _and: [
        { relations: { target_instance_id: { _eq: $id } } },
        { relations: { name: { _eq: "partOfSystem" } } }
      ]}
    ] }
  ) {
    id
    description
    name
    display_name
    version
    components: relationsByTargetInstanceId(where: { name: { _eq: "partOfComponent" } } ) {
      data: instance {
        id
        display_name
        description
        name
        shoppable
        consumable
        version
        descriptor
        external_id
        acls(where: { refs: { _in: $identities } }) {
          refs
          port_id
          locked
        }
      }
    }
    shoppable
    consumable
    descriptor
    kind
    external_id
    acls(where: { refs: { _in: $identities } }) {
      refs
      port_id
      locked
    }
  }
}
`;

export const getOutportTypesBuildWhere = (systemId: number) => {
  return {
    kind: { _eq: 'component' },
    relations: {
      _or: [
        {
          name: { _eq: 'partOfSystem' },
          instanceByTargetInstanceId: { id: { _eq: systemId } },
        },
        {
          name: { _eq: 'partOfComponent' },
          instanceByTargetInstanceId: {
            relations: {
              name: { _eq: 'partOfSystem' },
              instanceByTargetInstanceId: { id: { _eq: systemId } },
            },
          },
        },
      ],
    },
  };
};

export const GET_OUTPUTPORTTYPES = `
  query GetOutputPortType($where: marketplace_instances_bool_exp, $consumableInterfaceTypeField: String) {
    marketplace_instances(where: $where) {
      outputporttype: descriptor(path: $consumableInterfaceTypeField)
    }
  }
`;

export const GET_OUTPUT_PORTS_ID_BY_DATA_PRODUCT_INSTANCE_ID = `
query GetOutputPortsIdByDataProductInstanceId($id: bigint) {
  outputPorts: marketplace_instances(where: { _and: [
    { kind: { _eq: "component" } }
    { consumable: {_in: [${ConsumableMode.Consumable}, ${ConsumableMode.HasConsumableChild}] } }
    {relations: { _or: [
      { name: { _eq: "partOfSystem" }, instanceByTargetInstanceId: { id: { _eq: $id } } },
      { name: { _eq: "partOfComponent" }, instanceByTargetInstanceId: { relations: { name: { _eq: "partOfSystem" }, instanceByTargetInstanceId: { id: { _eq: $id } } } } }
    ] } }
  ]}) {
    id
  }
}
`;

export const GET_OUTPUT_PORTS_BY_ID = `
  query GetOutputPortsById($id: bigint, $consumableInterfaceTypeField: String) {
    outputPorts: marketplace_instances(where: { id: { _eq: $id } }) {
      id
      name
      displayName: display_name
      outputporttype: descriptor(path: $consumableInterfaceTypeField)
    }
  }
`;
