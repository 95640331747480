import {
  ConsumableInterfaceFilters,
  ConsumableMode,
  ShoppableMode,
} from '../types';

export type ResultType = {
  marketplace_instances: {
    id: number;
    name: string;
    displayName: string | null;
    consumableType: string | null;
    shoppable: ShoppableMode | null;
    consumable: ConsumableMode | null;
    components: {
      data: Omit<ResultType['marketplace_instances'][0], 'components'>;
    }[];
  }[];
  marketplace_instances_aggregate: {
    aggregate: {
      count: number;
    };
  };
};

const PROPERTIES_FRAGMENT = `
  id
  name
  displayName: display_name
  consumableType: descriptor(path: $consumableInterfaceTypeField)
  shoppable
  consumable
`;

export const QUERY = `
  query GetPaginatedACLResources(
    $where: marketplace_instances_bool_exp, 
    $subcomponentsWhere: marketplace_instances_bool_exp,
    $limit: Int,
    $offset: Int, 
    $consumableInterfaceTypeField: String, 
    $search : String) {
      marketplace_instances(limit: $limit
      offset: $offset
      where: $where) {
        ${PROPERTIES_FRAGMENT}
        components: relationsByTargetInstanceId(where: {
          _and: [
              { name: { _eq: "partOfComponent" } },
              { instance: $subcomponentsWhere }
          ]
        }) 
        {
          data: instance {
            ${PROPERTIES_FRAGMENT}
          }
        }
      }
      marketplace_instances_aggregate(where: $where) {
        aggregate {
          count
        }
      }
  }
`;

const buildWhereForFilters = (
  filters: ConsumableInterfaceFilters,
  consumableInterfaceTypeField: string,
  search?: string,
) => {
  const types = filters.type ?? [];
  return {
    _and: [
      {
        consumable: { _eq: 'CONSUMABLE' },
      },
      types.length
        ? {
            _or: [
              ...(types.reduce(
                (accumulator, curr) => [
                  ...accumulator,
                  {
                    descriptor: {
                      _contains: { [consumableInterfaceTypeField]: curr },
                    },
                  },
                ],
                [] as Record<string, any>[],
              ) ?? []),
            ],
          }
        : {},
    ],
    _or: [
      { display_name: { _ilike: `%${search || ''}%` } },
      {
        descriptor: {
          _contains: { [consumableInterfaceTypeField]: `${search || ''}` },
        },
      },
    ],
  };
};

const buildWhereClause = (
  filters: ConsumableInterfaceFilters,
  consumableInterfaceTypeField: string,
  search?: string,
  systemId?: number,
) => {
  return {
    _and: [
      {
        relations: {
          name: { _eq: 'partOfSystem' },
          instanceByTargetInstanceId: { id: { _eq: systemId } },
        },
      },
      {
        _or: [
          buildWhereForFilters(filters, consumableInterfaceTypeField, search),
          {
            relationsByTargetInstanceId: {
              name: { _eq: 'partOfComponent' },
              instance: buildWhereForFilters(
                filters,
                consumableInterfaceTypeField,
                search,
              ),
            },
          },
        ],
      },
    ],
  };
};

export const buildQueryVariables = (params: {
  limit: number;
  offset: number;
  filters: {
    type?: {
      label: string;
      rawFilters: string[];
    }[];
  };
  consumableTypeField: string;
  search: string;
  systemId?: number;
}) => ({
  limit: params.limit,
  offset: params.offset,
  where: buildWhereClause(
    {
      ...params.filters,
      type: params.filters.type?.flatMap(t => t.rawFilters),
    },
    params.consumableTypeField,
    params.search,
    params.systemId,
  ),
  subcomponentsWhere: buildWhereForFilters(
    {
      ...params.filters,
      type: params.filters.type?.flatMap(t => t.rawFilters),
    },
    params.consumableTypeField,
    params.search,
  ),
  consumableInterfaceTypeField: `$.${params.consumableTypeField}`,
});
