import { Box, makeStyles } from '@material-ui/core';
import React from 'react';

import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  container: {
    border: `1px solid ${theme.palette.grey[300]}`,
    display: 'flex',
    padding: '4px 8px',
    gap: '8px',
    minHeight: 65,
    maxHeight: 65,
    height: 65,
    borderRadius: '4px',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  row: {
    display: 'flex',
  },
  horizontalLine: {
    background: theme.palette.grey[300],
    width: '10px',
    position: 'absolute',
    height: '2px',
    top: 20,
    left: '-11px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  verticalLine: {
    background: theme.palette.grey[300],
    height: 200,
    position: 'absolute',
    width: '2px',
    top: 60,
    left: 10,
  },
  dot: {
    borderRadius: '100%',
    background: theme.palette.grey[300],
    width: '6px',
    height: '6px',
    marginRight: '-4px',
  },
}));

const CardLoader: React.FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Skeleton variant="circle" width={20} height={20} />

      <Box style={{ flex: 1 }} className={classes.column}>
        <Skeleton variant="text" height={20} />
        <Skeleton variant="text" />
      </Box>

      <Box style={{ display: 'flex', gap: '2px' }}>
        <Skeleton variant="circle" width={20} height={20} />
        <Skeleton variant="circle" width={20} height={20} />
        <Skeleton variant="circle" width={20} height={20} />
      </Box>
    </Box>
  );
};

export const WbTreeLoader: React.FC = () => {
  const classes = useStyles();
  return (
    <Box
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <CardLoader />

      <Box className={classes.verticalLine} />
      {[1, 2, 3].map(i => (
        <Box key={i} style={{ marginLeft: '22px', position: 'relative' }}>
          <Box className={classes.horizontalLine}>
            <Box className={classes.dot} />
          </Box>
          <CardLoader />
        </Box>
      ))}
    </Box>
  );
};
