import {
  ComponentInstanceEntity,
  InstanceEntity,
  SystemEntity,
} from '@agilelab/plugin-wb-marketplace-common';
import { ACL } from '@agilelab/plugin-wb-platform';
import { Entity } from '@backstage/catalog-model';

export type DataProductRow = {
  entity: InstanceEntity;
};

export class DependencyNodeId {
  private static NODE_ID_SEPARATOR = '_';

  dataProductInstanceId: string | undefined;
  portId: string | undefined; // either inputPort from where data product is reading from or outputPort where other dps are reading from you

  constructor(dataProductInstanceId?: string, portId?: string) {
    this.dataProductInstanceId = dataProductInstanceId;
    this.portId = portId;
  }

  public toString = (): string => {
    return `${this.dataProductInstanceId || ''}${
      DependencyNodeId.NODE_ID_SEPARATOR
    }${this.portId || ''}`;
  };

  public static parse(nodeId: string) {
    const [instanceId, portId] = nodeId.split(
      DependencyNodeId.NODE_ID_SEPARATOR,
    );

    return new DependencyNodeId(instanceId, portId);
  }
}

export interface DependencyNode {
  id: DependencyNodeId;
  name: string;
  domain: string;
  displayName: string;
  type: 'main' | 'external' | 'input' | 'output';
  version?: string;
  inputIds?: number[];
}

export interface ACLByOp extends ACL {
  warning?: boolean;
  entity?: Entity;
}

export interface ACLByRef extends ACL {
  id?: number;
  outputporttype?: string;
  type?: string;
  parent?: { id: number; displayName: string };
}

export type OutputPortWithWarning = {
  message: string;
  id: number;
  content: {
    validation: Record<string, Record<string, boolean>>;
    resultId: string;
  };
  kind: string;
};

export type OutputPortWithWarnings = ComponentInstanceEntity & {
  mode: string;
  outputporttype: string;
  _portWarnings: Array<OutputPortWithWarning>;
};

export type ExternalOutputPort = {
  display_name: string;
  external_id: string;
  id: string;
  system: SystemEntity[];
};

/**
 * A different naming for the same data type, to differentiate the phase when the general info has been rendered and when not
 * RawGeneralInfo represents the info that are not rendered yet
 */
export type RawGeneralInfo = GeneralInfo;

export type GeneralInfo = {
  type: 'string' | 'date';
  label: string;
  value: string | undefined;
  href?: string;
  colSpan?: number;
};

export type TechInfoObject = {
  [key: string]: GeneralInfo;
};
