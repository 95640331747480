import { AccessControlRequestNotification } from '@agilelab/plugin-wb-notification-common';
import {
  customAlertApiRef,
  ProgressButton,
  useUsername,
  useUserProfile,
} from '@agilelab/plugin-wb-platform';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { Box, Chip, Typography, useTheme } from '@material-ui/core';
import { DateTime } from 'luxon';
import React, { useCallback, useState } from 'react';
import { useNotificationStyle } from './NotificationsPageStyle';
import { notificationApiRef } from '../../notificationApiRef';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import useAsync from 'react-use/lib/useAsync';
import { Entity } from '@backstage/catalog-model';
import { userCache } from './cache';
import {
  NotificationAccordion,
  NotificationAccordionAction,
  NotificationAccordionDetails,
  NotificationAccordionSummary,
} from './NotificationAccordion';
import { Avatar, Link } from '@backstage/core-components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { JsonObject } from 'type-fest';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import { MarkAsReadButton } from './MarkAsReadButton';
import { useQueryClient } from '@tanstack/react-query';
import {
  anyCaseToCapitalCase,
  CustomError,
} from '@agilelab/plugin-wb-platform-common';
import { useNotification } from './useNotificationProvider';
import { AccessControlDisplayFields } from './AccessControlDisplayFields';
import {
  AccessControlRequestStatus,
  AclVerb,
} from '@agilelab/plugin-wb-access-control-common';

const parseRequesterName = (
  requesterName: string,
): { kind: string; name: string } => {
  const splittedRequesterName = requesterName.split(':');

  return { kind: splittedRequesterName[0], name: splittedRequesterName[1] };
};

const composeMessages = (
  verb: AclVerb,
): { deliverySuccess: string; deliveryFailure: string } => {
  if (verb === AclVerb.Grant) {
    return {
      deliverySuccess:
        'Access request accepted! A follow-up message will inform you when the access is effective into the system.',
      deliveryFailure: 'Failed to grant access',
    };
  }

  return {
    deliverySuccess:
      'Revoke request accepted! A follow-up message will inform you when the revoke is effective into the system.',
    deliveryFailure: 'Failed to revoke access',
  };
};

export const AccessControlRequestNotificationAccordion = (props: {
  verb: AclVerb;
  notification: AccessControlRequestNotification;
  onAccordionExpansionsChange: (value: string | boolean) => void;
  expandedAccordion: string | boolean;
}) => {
  const { verb, notification, onAccordionExpansionsChange, expandedAccordion } =
    props;
  const classes = useNotificationStyle();
  const catalogApi = useApi(catalogApiRef);
  const theme = useTheme();
  const notificationCatalog = useApi(notificationApiRef);
  const identityApi = useApi(identityApiRef);
  const alertApi = useApi(customAlertApiRef);
  const { profile } = useUserProfile();
  const loggedUsername = useUsername();
  const [loading, setLoading] = useState('');
  const queryClient = useQueryClient();
  const { setRead } = useNotification();

  const { value: askerEntity } = useAsync(async (): Promise<
    Entity | undefined
  > => {
    if (userCache.has(notification.notification_request.requester))
      return userCache.get(notification.notification_request.requester)!;
    const userEntity = await catalogApi.getEntityByRef({
      name: parseRequesterName(notification.notification_request.requester)
        .name,
      namespace: 'default',
      kind: 'user',
    });
    if (userEntity) {
      userCache.set(notification.notification_request.requester, userEntity);
    }
    return userEntity;
  }, [notification]);

  const onToggle = useCallback(
    (_event: object, expanded: boolean) => {
      onAccordionExpansionsChange(
        expanded ? notification.id.toString() : false,
      );
    },
    [notification.id, onAccordionExpansionsChange],
  );

  const updateAccessControlRequestResponse = useCallback(
    async (status: AccessControlRequestStatus) => {
      setLoading(status);
      try {
        await notificationCatalog
          .updateNotificationResponse(
            notification.id.toString(),
            {
              authorized_by: loggedUsername?.username || profile.email,
              authorized_by_display_name:
                loggedUsername?.displayName || profile.displayName,
              status: status,
              timestamp: new Date().toJSON(),
            },
            await identityApi.getCredentials(),
          )
          .then(
            _ => {
              if (status === AccessControlRequestStatus.ACCEPT) {
                alertApi.post({
                  message: composeMessages(verb).deliverySuccess,
                  severity: 'success',
                });
              }
              queryClient.invalidateQueries({
                queryKey: ['paginatedByRecipient'],
              });
              setRead(notification.id.toString());
              setLoading('');
            },
            error => {
              alertApi.post({
                error,
                severity: 'error',
              });
              setLoading('');
            },
          );
      } catch (error) {
        alertApi.post({
          error: new CustomError(
            composeMessages(verb).deliveryFailure,
            error.message,
          ),
          severity: 'error',
          timeoutMillis: 5000,
        });
        setLoading('');
      }
    },
    [
      alertApi,
      identityApi,
      loggedUsername?.displayName,
      loggedUsername?.username,
      notification.id,
      notificationCatalog,
      profile.displayName,
      profile.email,
      queryClient,
      setRead,
      verb,
    ],
  );

  return (
    <NotificationAccordion
      onChange={onToggle}
      expanded={expandedAccordion === notification.id.toString()}
      style={{
        backgroundColor: !!notification.read_at
          ? 'transparent'
          : theme.palette.common.white,
      }}
    >
      <NotificationAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div className={classes.summary}>
          <Box>
            <div className={classes.summaryHeader}>
              <Typography className={classes.notificationTime}>
                {DateTime.fromISO(notification.created_at).toFormat(
                  'dd/MM/yyyy HH:mm',
                )}
              </Typography>
              {!notification.notification_response && (
                <ErrorOutlineOutlinedIcon fontSize="small" color="primary" />
              )}
              {notification.notification_response?.status ===
                AccessControlRequestStatus.ACCEPT && (
                <CheckCircleOutlineOutlinedIcon
                  fontSize="small"
                  style={{ color: theme.palette.success.main }}
                />
              )}
              {notification.notification_response?.status ===
                AccessControlRequestStatus.REJECT && (
                <NotInterestedIcon
                  fontSize="small"
                  style={{ color: theme.palette.error.main }}
                />
              )}
            </div>
            <div className={classes.summaryDescription}>
              <Avatar
                displayName={
                  notification.notification_request.requester_display_name
                }
                picture={
                  (askerEntity?.spec?.profile as JsonObject | undefined)
                    ?.picture as string
                }
                customStyles={{
                  width: theme.spacing(7),
                  height: theme.spacing(7),
                }}
              />
              <div className={classes.summaryDescriptionText}>
                <b>
                  {notification.notification_request.requester_display_name}
                </b>{' '}
                <Typography variant="subtitle2">
                  {verb === AclVerb.Grant
                    ? 'requested access to a resource'
                    : 'requested a revoke for a resource'}
                </Typography>
                <span>
                  <b>name:</b>{' '}
                  <Link
                    to={`/marketplace/search/${notification.notification_request.id_dataproduct_instance}`}
                  >
                    <Typography color="primary" component="b">
                      {notification.notification_request.dataproduct
                        .display_name ||
                        notification.notification_request.dataproduct.name}
                    </Typography>
                  </Link>
                </span>
                <span>
                  <b>domain:</b>{' '}
                  <Typography color="primary" component="b">
                    {notification.notification_request.dataproduct.domain}
                  </Typography>
                </span>
                <span>
                  <b>environment:</b>{' '}
                  <Typography color="primary" component="b">
                    {notification.notification_request.environment}
                  </Typography>
                </span>
                <span>
                  <b>version:</b>{' '}
                  <Typography color="primary" component="b">
                    {notification.notification_request.dataproduct.version}
                  </Typography>
                </span>
              </div>
            </div>
          </Box>
          <Box alignSelf="center">
            <MarkAsReadButton notification={notification} />
          </Box>
        </div>
      </NotificationAccordionSummary>
      <NotificationAccordionDetails>
        <div>
          <div className={classes.contentElementHeader}>
            <Typography
              variant="h6"
              color="primary"
              style={{ marginBottom: theme.spacing(1) }}
            >
              Consumable Interface
            </Typography>
            <Typography variant="body1">
              <b>Name:</b> {notification.notification_request.outputport.name}
            </Typography>
            <Typography variant="body1">
              <b>Type:</b>{' '}
              {anyCaseToCapitalCase(
                notification.notification_request.outputport.output_port_type,
                true,
              )}
            </Typography>
          </div>
          <div className={classes.contentElementHeader}>
            <Typography
              variant="h6"
              color="primary"
              style={{ marginBottom: theme.spacing(1) }}
            >
              Requested for
            </Typography>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: theme.spacing(1),
              }}
            >
              {notification.notification_request.identities &&
                notification.notification_request.identities.map(i => (
                  <Chip title={i} key={i} label={i} size="small" />
                ))}
            </div>
          </div>
          <AccessControlDisplayFields
            notification={notification.notification_request}
          />
          {notification.notification_response?.status ===
            AccessControlRequestStatus.ACCEPT && (
            <Typography style={{ color: theme.palette.success.main }}>
              Request accepted on:{' '}
              {DateTime.fromISO(
                notification.notification_response?.timestamp,
              ).toFormat('dd/MM/yyyy HH:mm')}
            </Typography>
          )}
          {notification.notification_response?.status ===
            AccessControlRequestStatus.REJECT && (
            <Typography style={{ color: theme.palette.error.main }}>
              Access rejected :{' '}
              {DateTime.fromISO(
                notification.notification_response?.timestamp,
              ).toFormat('dd/MM/yyyy HH:mm')}
            </Typography>
          )}
        </div>
      </NotificationAccordionDetails>
      <NotificationAccordionAction>
        {!notification.notification_response && (
          <>
            <ProgressButton
              meshColor="red"
              variant="contained"
              inProgress={loading === AccessControlRequestStatus.REJECT}
              disabled={loading === AccessControlRequestStatus.ACCEPT}
              onClick={() =>
                updateAccessControlRequestResponse(
                  AccessControlRequestStatus.REJECT,
                )
              }
            >
              Reject
            </ProgressButton>
            <ProgressButton
              meshColor="green"
              variant="contained"
              inProgress={loading === AccessControlRequestStatus.ACCEPT}
              disabled={loading === AccessControlRequestStatus.REJECT}
              onClick={() =>
                updateAccessControlRequestResponse(
                  AccessControlRequestStatus.ACCEPT,
                )
              }
            >
              Accept
            </ProgressButton>
          </>
        )}
      </NotificationAccordionAction>
    </NotificationAccordion>
  );
};
