import {
  CreationPolicy,
  Policy,
  PolicyGql,
} from '@agilelab/plugin-wb-governance-common';

import {
  parseInfrastructureTemplateIdFromPreprocessing,
  parseUseCaseTemplateIdFromPreprocessing,
} from './utils';

export const toCreateEditPolicy = (gqlPolicy: PolicyGql): CreationPolicy => {
  let parsedMetadata = {};

  try {
    parsedMetadata = JSON.parse(gqlPolicy.additional_metadata);
  } catch (error) {
    parsedMetadata = {};
  }

  return {
    id: String(gqlPolicy.id),
    groupId: gqlPolicy.group_id,
    status: gqlPolicy.status,
    name: gqlPolicy.name,
    description: gqlPolicy.description || '',
    context: gqlPolicy.context,
    severity: gqlPolicy.content.severity,
    strategy: gqlPolicy.strategy,
    engine: gqlPolicy.engine,
    resourceType: [gqlPolicy.resource_type],
    version: gqlPolicy.version,
    selectors: gqlPolicy.selector?.map(s => ({ path: s.jsonPath, ...s })) || [],
    tags: gqlPolicy.governance_entity_tags.map(t => t.tag.name),
    cueSpecific: {
      cueScript: gqlPolicy.content.cueScript || '',
    },
    externalSpecific: {
      externalUrl: gqlPolicy.content.externalUrl || '',
    },
    governanceAgentSpec:
      {
        url: gqlPolicy.content.governanceAgentSpec?.url || '',
        prompt: gqlPolicy.content.governanceAgentSpec?.prompt || '',
      } || '',
    timing: gqlPolicy.timing,
    updateTime: new Date(gqlPolicy.update_time),
    policyId: gqlPolicy.governance_entity_id,
    cronExpression: gqlPolicy.cron_expression || undefined,
    interactionType: gqlPolicy.interaction_type,
    environments:
      gqlPolicy.governance_entity_environments?.map(e => e.environment.name) ||
      [],
    preprocessing: gqlPolicy.preprocessing || undefined,
    infrastructureTemplateId: parseInfrastructureTemplateIdFromPreprocessing(
      gqlPolicy.preprocessing,
    ),
    useCaseTemplateId: parseUseCaseTemplateIdFromPreprocessing(
      gqlPolicy.preprocessing,
    ),
    additionalMetadata: parsedMetadata,
    trigger: gqlPolicy.trigger,
  };
};

export const toGovernancePolicy = (gqlPolicy: PolicyGql): Policy => {
  let parsedMetadata = {};

  try {
    parsedMetadata = JSON.parse(gqlPolicy.additional_metadata);
  } catch (error) {
    parsedMetadata = {};
  }

  return {
    id: String(gqlPolicy.id),
    groupId: gqlPolicy.group_id,
    status: gqlPolicy.status,
    name: gqlPolicy.name,
    description: gqlPolicy.description || '',
    context: gqlPolicy.context,
    severity: gqlPolicy.content.severity,
    strategy: gqlPolicy.strategy,
    engine: gqlPolicy.engine,
    resourceType: gqlPolicy.resource_type.name,
    version: gqlPolicy.version,
    selectors: gqlPolicy.selector?.map(s => ({ path: s.jsonPath, ...s })) || [],
    tags: gqlPolicy.governance_entity_tags.map(t => t.tag.name),
    cueSpecific: {
      cueScript: gqlPolicy.content.cueScript || '',
    },
    externalSpecific: {
      externalUrl: gqlPolicy.content.externalUrl || '',
    },
    governanceAgentSpec: {
      url: gqlPolicy.content.governanceAgentSpec?.url || '',
      prompt: gqlPolicy.content.governanceAgentSpec?.prompt || '',
    },
    timing: gqlPolicy.timing,
    updateTime: new Date(gqlPolicy.update_time),
    policyId: gqlPolicy.governance_entity_id,
    cronExpression: gqlPolicy.cron_expression || undefined,
    interactionType: gqlPolicy.interaction_type,
    environments:
      gqlPolicy.governance_entity_environments?.map(e => e.environment.name) ||
      [],
    preprocessing: gqlPolicy.preprocessing || undefined,
    infrastructureTemplateId: parseInfrastructureTemplateIdFromPreprocessing(
      gqlPolicy.preprocessing,
    ),
    useCaseTemplateId: parseUseCaseTemplateIdFromPreprocessing(
      gqlPolicy.preprocessing,
    ),
    additionalMetadata: parsedMetadata,
    trigger: gqlPolicy.trigger,
  };
};
