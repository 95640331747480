import { GenericEntityType, TemplateEntity, TextFieldValue } from '../types';
import { parseEntityRef } from '@backstage/catalog-model';
import {
  generateURNByKind,
  transformUrnToWitboostId,
} from '@agilelab/plugin-wb-builder-common';
import {
  compactCompoundEntityRef,
  parseNunjucks,
} from '@agilelab/plugin-wb-platform-common';
import { asEntity } from '../../../types';
import { KindEnum } from '..';

export const selectTemplateEntity = (
  availableKinds: TemplateEntity[],
  selectedKind: string,
) => {
  return availableKinds.find(
    availableKind => availableKind.type === selectedKind,
  );
};

// TODO: refactor for extensibility (i.e., support multiple providers with different object types)
export function adaptEntity(entity: GenericEntityType): {
  name: string;
  kind: KindEnum;
  label: string;
  urn: string;
  ref: string;
} {
  const castedEntity = asEntity(entity);
  if (castedEntity) {
    return {
      name: entity.metadata.name,
      kind: entity.__metadata?.kind ?? entity.kind,
      label: entity.spec?.mesh?.name ?? entity.metadata?.name,
      urn: generateURNByKind(entity.metadata.name, entity.kind, true),
      ref: compactCompoundEntityRef({
        kind: castedEntity.kind,
        name: castedEntity.metadata.name,
        namespace: 'default',
      }),
    };
  }

  const consumableInterfaceRef = transformUrnToWitboostId(entity.id);
  if (!consumableInterfaceRef) {
    throw new Error(
      `Invalid URN. Check your entity id: ${JSON.stringify(entity)}`,
    );
  }
  const consumableInterfaceCompoundRef = parseEntityRef(consumableInterfaceRef);

  return {
    name: consumableInterfaceCompoundRef.name,
    kind: entity.__metadata?.kind ?? KindEnum.consumable,
    label: entity.name,
    urn: entity.id,
    // for consumableInterface only urn or fullObj could be returned
    // for fault tolerance urn will be returned if ref is selected on the template
    ref: entity.id,
  };
}

export function getLabel(
  entity: GenericEntityType,
  templateEntity: TemplateEntity,
) {
  const displayField = templateEntity.displayField;
  return parseNunjucks(displayField, entity);
}

export function getLabelAndValue(
  entity: GenericEntityType,
  templateEntity?: TemplateEntity,
): TextFieldValue {
  if (!templateEntity)
    return {
      kind: KindEnum.arbitrary,
      label: entity.__metadata.name,
      value: entity,
    };
  const parsedNunjucks = getLabel(entity, templateEntity);
  const adaptedEntity = adaptEntity(entity);

  const kindDisplayName = templateEntity.displayName;

  if ('returnField' in templateEntity) {
    if (templateEntity.returnField?.toLowerCase() === 'urn') {
      return {
        kind: adaptedEntity.kind,
        kindDisplayName,
        label: parsedNunjucks ?? adaptedEntity.label,
        value: adaptedEntity.urn,
      };
    }

    if (templateEntity.returnField === 'ref') {
      return {
        kind: adaptedEntity.kind,
        kindDisplayName,
        label: parsedNunjucks ?? adaptedEntity.label,
        value: adaptedEntity.ref,
      };
    }

    if (templateEntity.returnField?.toLowerCase() === 'full') {
      return {
        kind: adaptedEntity.kind,
        kindDisplayName,
        label: parsedNunjucks ?? adaptedEntity.label,
        value: entity,
      };
    }
  }

  return {
    kind: adaptedEntity.kind,
    label: parsedNunjucks ?? adaptedEntity.label,
    value: adaptedEntity.urn,
  };
}
