import {
  Column,
  GenericEntityType,
  Pagination,
  TableCellProps,
  TemplateEntity,
  TextFieldValue,
  WbTable,
} from '@agilelab/plugin-wb-platform';
import {
  Checkbox,
  createStyles,
  FormControl,
  makeStyles,
  Radio,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { isEqual } from 'lodash';
import { getLabelAndValue } from './utils';
import { parseNunjucks } from '@agilelab/plugin-wb-platform-common';

const renderRow = (row: GenericEntityType, path: string) => {
  const parsedNunjuck = parseNunjucks(path, row);
  return <Typography>{parsedNunjuck}</Typography>;
};

interface SelectableEntityTableProps {
  loading: boolean;
  data: GenericEntityType[];
  entities: TextFieldValue[];
  onChangeHandler: (entities: TextFieldValue[]) => void;
  filtersSection: () => React.JSX.Element;
  count: number;
  pagination: Pagination;
  columns: Column[];
  setPagination: React.Dispatch<React.SetStateAction<Pagination>>;
  multiple?: boolean;
  prevCursor?: string;
  nextCursor?: string;
  templateEntity: TemplateEntity;
}

const useStyles = makeStyles(
  () =>
    createStyles({
      formControl: {
        width: '100%',
        overflow: 'auto',
        height: '100%',
        justifyContent: 'space-between',
      },
    }),
  { name: 'selectionTable' },
);

export const SelectableEntityTable: React.FC<SelectableEntityTableProps> = ({
  loading,
  data,
  entities,
  onChangeHandler,
  filtersSection,
  multiple = false,
  pagination,
  setPagination,
  count,
  prevCursor,
  nextCursor,
  columns,
  templateEntity,
}) => {
  const classes = useStyles();

  const handleChangePage = (newPage: number) => {
    setPagination(v => ({
      limit: v.limit ?? 0,
      currentPage: newPage,
      cursor: pagination.currentPage > newPage ? prevCursor : nextCursor,
    }));
  };

  const handleChangeRowsPerPage = (limit: number) => {
    setPagination({
      limit: limit,
      currentPage: 0,
      cursor: undefined,
    });
  };

  const Selector = ({ row }: { row: GenericEntityType }) => {
    const adaptedRow = getLabelAndValue(row, templateEntity);

    if (templateEntity.displayName) {
      adaptedRow.kindDisplayName = templateEntity.displayName;
    }

    return multiple ? (
      <>
        <Checkbox
          onChange={(event, checked) => {
            event.stopPropagation();

            if (checked) {
              onChangeHandler([...entities, adaptedRow]);
            } else {
              onChangeHandler([
                ...entities?.filter(e => !isEqual(e, adaptedRow)),
              ]);
            }
          }}
          checked={
            !!entities?.find((e: TextFieldValue) => {
              return isEqual(e, adaptedRow);
            })
          }
          color="primary"
        />
      </>
    ) : (
      <Radio
        onClick={e => {
          e.stopPropagation();
          onChangeHandler([adaptedRow]);
        }}
        checked={isEqual(entities[0], adaptedRow)}
        color="primary"
      />
    );
  };

  const handleColumns = (): TableCellProps<GenericEntityType>[] => {
    const modifiedColumns = columns.map(column => ({
      field: column.name,
      fieldRender: (row: GenericEntityType) => renderRow(row, column.path),
      headerName: column.name,
    }));

    return [
      {
        field: 'selector',
        fieldRender: (row: GenericEntityType) => <Selector row={row} />,
        headerName: '',
        cellProps: {
          width: '5%',
        },
      },
      ...modifiedColumns,
    ];
  };

  return (
    <>
      {filtersSection()}
      <FormControl className={classes.formControl}>
        <WbTable
          styles={{
            container: {
              flex: 1,
            },
          }}
          components={{
            tableLoader: { loading },
            tableContent: {
              columns: handleColumns(),
              rows: data,
            },
          }}
          pagination={{
            countlessOptions: pagination.countlessOptions,
            rowsPerPageOptions: [5, 10, 25],
            count: count,
            limit: pagination.limit ?? 0,
            currentPage: pagination.currentPage,
            onPageChange: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
        />
      </FormControl>
    </>
  );
};
