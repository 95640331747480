import { FormControl, TextField, TextFieldProps } from '@material-ui/core';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { ClearButton } from './ClearButton';
import { WbMarkdownHelperText } from './WbMarkdownHelperText';
import { useStyles } from './styles';

type Props = TextFieldProps & {
  // specifies the number of ms the onChange towards the parent shoud be debounced (if onChange is not passed it has no effect)
  debounceMs?: number;
  clearable?: boolean;
  cursorType?: 'pointer';
};

export const WbTextField = React.forwardRef(
  (
    {
      InputProps,
      SelectProps,
      InputLabelProps,
      clearable = false,
      placeholder,
      debounceMs,
      helperText,
      fullWidth,
      value: parentValue,
      onChange: parentOnChange,
      ...props
    }: Props,
    ref?: React.Ref<any>,
  ) => {
    const classes = useStyles();

    // used as the actual value if no value is passed from the parent (uncontrolled) or while debouncing, to allow teh user to type until the parent value is updated as well
    const [innerValue, setInnerValue] = useState(parentValue || '');

    useEffect(() => {
      setInnerValue(parentValue || '');
    }, [parentValue]);

    // when true there is a debouncing going on
    const [isDebouncing, setIsDebouncing] = useState(false);

    const hiddenInput = props.style?.display === 'none';
    // if value is not passed from the parent or there currently is a debounce going on, show the innerValue instead of the value from the parent
    let value = innerValue;
    if (parentValue !== undefined && parentValue !== null && !isDebouncing)
      value = parentValue;

    const onChange = useMemo(() => {
      // if debounceMs is specified, use a debounced version of the parent onChange instead of the original
      if (debounceMs && parentOnChange)
        return debounce(e => {
          // at the end of debounce, update the parent and notify that the debouncing is over, so that the parent value is shown again instead of the innerValue
          parentOnChange(e);
          setIsDebouncing(false);
        }, debounceMs);
      return parentOnChange;
    }, [debounceMs, parentOnChange]);

    return (
      <FormControl fullWidth={fullWidth}>
        <TextField
          placeholder={placeholder}
          inputRef={ref}
          fullWidth={fullWidth}
          variant="outlined"
          onChange={e => {
            if (debounceMs && onChange) setIsDebouncing(true);
            setInnerValue(e.target.value);
            if (onChange) onChange(e);
          }}
          size="small"
          classes={{
            root: classes.root,
          }}
          helperText={
            helperText && typeof helperText !== 'string'
              ? helperText
              : undefined
          }
          InputProps={{
            classes: {
              root: classes.inputRoot,
              input: `${classes.input}${
                props.cursorType === 'pointer'
                  ? ` ${classes.pointerCursor}`
                  : ''
              }`,
            },
            endAdornment: clearable && !props.disabled && (
              <ClearButton
                style={{ marginRight: -6 }}
                onClear={e => {
                  const event = e as unknown as React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >;
                  setInnerValue('');
                  event.target.value = '';
                  if (parentOnChange) parentOnChange(event);
                }}
              />
            ),
            ...InputProps,
          }}
          SelectProps={{
            classes: {
              root: classes.selectRoot,
              outlined: classes.selectOutlined,
            },
            ...SelectProps,
          }}
          InputLabelProps={{
            classes: {
              root: classes.inputLabelRoot,
            },
            shrink: true,
            ...InputLabelProps,
          }}
          value={value}
          {...props}
        />
        {helperText && typeof helperText === 'string' && !hiddenInput && (
          <WbMarkdownHelperText helperText={helperText} />
        )}
      </FormControl>
    );
  },
);

WbTextField.displayName = 'WbTextField';
