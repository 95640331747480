import {
  entityRefToUrn,
  parseUrn,
  transformUrnToWitboostId,
  WitboostSystem,
} from '@agilelab/plugin-wb-builder-common';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { CatalogApi, catalogApiRef } from '@backstage/plugin-catalog-react';
import React, { useEffect, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { parseEntityRef, stringifyEntityRef } from '@backstage/catalog-model';
import {
  Column,
  GenericEntityType,
  GenericProviderType,
  getLabelAndValue,
  KindEnum,
  Pagination,
  Provider,
  selectTemplateEntity,
  TemplateEntity,
} from '@agilelab/plugin-wb-platform';
import { contextProviderFactory } from '../entityProvider.factory';
import { useColumns } from '../hooks';
import {
  DataContractFilters,
  DataContractFiltersSection,
} from './DataContractFilters';
import {
  collectDataContractsFromComponent,
  DataContractComponent,
  DataContractEntity,
  DataContractSubcomponent,
  parseNunjucks,
} from '@agilelab/plugin-wb-platform-common';
import { PART_OF } from '@agilelab/plugin-wb-practice-shaper-common';

export type DataContractTypeEntity = GenericEntityType & DataContractEntity;

interface DataContractContextType
  extends GenericProviderType<DataContractTypeEntity[], DataContractFilters> {}

const [useDataContractContext, DataContractContextProvider] =
  contextProviderFactory<DataContractContextType>();

export default useDataContractContext;

export interface DataContractProviderProps {
  templateKind: TemplateEntity | undefined;
  children: React.ReactNode;
  parentTemplateRef?: string;
  /**
   * This is needed in order to access the picker of the system,
   * that holds data contracts.
   */
  formContext: any;
}

function isRawComponent(
  component: DataContractEntity,
): component is DataContractComponent {
  return (component as DataContractComponent).metadata?.name !== undefined;
}

function toDataContractTypeEntity(
  dataContract: DataContractEntity,
): DataContractTypeEntity {
  const isComponent = isRawComponent(dataContract);
  if (isComponent) {
    return {
      ...dataContract,
      __metadata: {
        kind: KindEnum.dataContract,
        name: dataContract.metadata.name,
      },
    };
  }

  const { name } = parseEntityRef(dataContract.__parentEntityRef);
  const subcomponentName = `${name}.${dataContract.name}`;
  const subcomponentFullRef = `component:${subcomponentName}`;

  return {
    ...dataContract,
    __metadata: {
      kind: KindEnum.dataContract,
      name: subcomponentName,
    },
    id: entityRefToUrn(subcomponentFullRef),
  };
}

const defaultColumns = new Map<string, string>([
  [
    'name',
    '{% if spec.mesh.name %}{{spec.mesh.name}}{% else %}{{name}}{% endif %}',
  ],
  [
    'description',
    '{% if metadata.description %}{{metadata.description}}{% else %}{{description}}{% endif %}',
  ],
]);

export const DataContractProvider: React.FC<
  DataContractProviderProps
> = props => {
  const { templateKind, children, parentTemplateRef, formContext } = props;
  const catalogApi = useApi(catalogApiRef);
  const identityApi = useApi(identityApiRef);
  const [filters, setFilters] = useState<DataContractFilters>({});
  const [nextCursor, setNextCursor] = useState<string | undefined>(undefined);
  const [prevCursor, setPrevCursor] = useState<string | undefined>(undefined);
  const [count, setCount] = useState<number>(0);
  const [pagination, setPagination] = useState<Pagination>({
    limit: 5,
    currentPage: 0,
  });
  const [preFilteredSystem, setPreFilteredSystem] = useState<WitboostSystem>();

  const columns: Column[] = useColumns(defaultColumns, templateKind);

  const preFilters = templateKind?.filter;

  /**
   * This effect is used to populate the system with the correct one from the system picker.
   * It uses the pre-filter condition based on the picker name, by leveraging nunjucks.
   */
  useEffect(() => {
    const getDataContractTypes = async () => {
      const systemPickerNameNunjucks = preFilters?.system as string | undefined;

      if (systemPickerNameNunjucks) {
        const resolvedSystemName = parseNunjucks(
          systemPickerNameNunjucks,
          formContext,
        );
        if (!resolvedSystemName) return;
        const selectedSystem = (await catalogApi.getEntityByRef(
          resolvedSystemName,
        )) as WitboostSystem | undefined;
        if (selectedSystem) {
          setPreFilteredSystem(selectedSystem);
        }
      }
    };

    getDataContractTypes();
  }, [catalogApi, formContext, identityApi, parentTemplateRef, preFilters]);

  const dataContractsState = useAsync(async () => {
    const systemRefs = filters.system?.map(s =>
      stringifyEntityRef({
        kind: 'system',
        name: s.name,
      }),
    );
    const result = await catalogApi.queryEntities({
      filter: {
        [`relations.${PART_OF}`]: preFilteredSystem
          ? [stringifyEntityRef(preFilteredSystem)]
          : systemRefs ?? [],
        kind: ['Component'],
      },
    });

    const dataContracts: DataContractEntity[] = [];
    result.items.forEach(i => {
      const c = i as DataContractComponent;
      dataContracts.push(...collectDataContractsFromComponent(c));
    });

    // We filter here and not in the query because subcomponents are not entities in the catalog.
    const filteredDataContracts = dataContracts
      .filter(dc => {
        if (!filters.search || filters.search === '') return true;
        const lowercasedSearch = filters.search.toLowerCase();
        if (isRawComponent(dc))
          return dc.spec?.mesh?.name.toLowerCase().includes(lowercasedSearch);
        return dc.name.toLowerCase().includes(lowercasedSearch);
      })
      .map(dc => toDataContractTypeEntity(dc));

    const totalItems = filteredDataContracts.length;
    const totalPages = Math.ceil(totalItems / pagination.limit);
    const currentPage = pagination.currentPage;

    const paginatedDataContracts = filteredDataContracts.slice(
      currentPage * pagination.limit,
      (currentPage + 1) * pagination.limit,
    );

    setNextCursor(
      currentPage < totalPages - 1 ? String(currentPage + 1) : undefined,
    );
    setPrevCursor(currentPage > 0 ? String(currentPage - 1) : undefined);
    setCount(totalItems);
    return paginatedDataContracts;
  }, [
    templateKind,
    filters,
    preFilteredSystem,
    pagination.limit,
    pagination.cursor,
  ]);

  return (
    <DataContractContextProvider
      value={{
        entitiesState: dataContractsState,
        columns,
        filters,
        changeFilters: <K extends keyof DataContractFilters>(
          key: K,
          filterValue: DataContractFilters[K],
        ) => {
          setPagination(p => ({ ...p, currentPage: 0, cursor: undefined }));
          setFilters(f => ({ ...f, [key]: filterValue }));
        },
        resetFilters: () => {
          setFilters({});
        },
        templateKind,
        pagination,
        setPagination,
        count,
        nextCursor,
        prevCursor,
      }}
    >
      {children}
    </DataContractContextProvider>
  );
};

export class DataContractHandlerProvider
  implements Provider<DataContractTypeEntity[], DataContractFilters>
{
  private readonly formContext: any;
  private readonly availableKinds: TemplateEntity[];
  private readonly catalogApi: CatalogApi;

  constructor(
    formContext: any,
    catalogApi: CatalogApi,
    availableKinds: TemplateEntity[],
  ) {
    this.formContext = formContext;
    this.catalogApi = catalogApi;
    this.availableKinds = availableKinds;
  }

  useContext = useDataContractContext;

  renderContextProvider(
    templateKind: TemplateEntity | undefined,
    children: React.ReactNode,
    parentTemplateRef?: string,
  ) {
    return (
      <DataContractProvider
        templateKind={templateKind}
        parentTemplateRef={parentTemplateRef}
        formContext={this.formContext}
      >
        {children}
      </DataContractProvider>
    );
  }

  filtersSection = DataContractFiltersSection;

  async resolve(urn: string) {
    const { subcomponent, component: componentName } = parseUrn(urn);
    if (!componentName) throw new Error(`No component name found`);
    const ref = transformUrnToWitboostId(
      urn.replace(subcomponent ? `:${subcomponent}` : '', ''),
    );
    if (!ref) throw new Error(`No ref found`);
    let entity: DataContractEntity | undefined =
      await this.catalogApi.getEntityByRef(ref);
    if (!entity) throw new Error(`No entity found with ref: ${ref}`);
    try {
      if (subcomponent) {
        const component = entity;
        const dataContracts = collectDataContractsFromComponent(component);
        entity =
          dataContracts
            .filter(dc => !isRawComponent(dc))
            .find(
              dc => (dc as DataContractSubcomponent).name === subcomponent,
            ) ?? entity;
      }

      const templateEntity = selectTemplateEntity(
        this.availableKinds,
        'DataContract',
      );
      if (templateEntity?.returnField === 'ref')
        templateEntity.returnField = 'urn';

      return getLabelAndValue(toDataContractTypeEntity(entity), templateEntity);
    } catch (error) {
      throw new Error(`Error while resolve entity with urn: ${urn}. ${error}`);
    }
  }
}
