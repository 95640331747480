import {
  ConfirmDialog,
  WbCardActionButton,
  WbCardContent,
  WbDivider,
  WbMultiSelect,
  WbSelect,
  WbTextField,
  WbWidget,
} from '@agilelab/plugin-wb-platform';
import {
  getCronResult,
  getCronValidationMessage,
} from '@agilelab/plugin-wb-platform-common';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles(() => ({
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    rowGap: '1rem',
    columnGap: '1rem',
  },
  singleInputContainer: {
    gridColumn: 'span 2',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    paddingRight: '1rem',
  },
  checkboxContainer: {
    display: 'flex',
  },
  checkboxListElement: {
    padding: '0 4px',
  },
  daysOfWeek: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    marginBottom: 10,
  },
}));
export enum CronTypes {
  YEAR = 'year',
  MONTH = 'month',
  DAY_OF_MONTH = 'dayOfMonth',
  DAY_OF_WEEK = 'dayOfWeek',
  HOUR = 'hour',
  MINUTE = 'minute',
  SECOND = 'second',
}

export enum Frequency {
  YEAR = 1,
  MONTH = 2,
  WEEK = 3,
  DAY = 4,
  HOUR = 5,
  MINUTE = 6,
  SECOND = 7,
}

export enum Second {
  Every = 'every',
  Zero = 0,
  One,
  Two,
  Three,
  Four,
  Five,
  Six,
  Seven,
  Eight,
  Nine,
  Ten,
  Eleven,
  Twelve,
  Thirteen,
  Fourteen,
  Fifteen,
  Sixteen,
  Seventeen,
  Eighteen,
  Nineteen,
  Twenty,
  TwentyOne,
  TwentyTwo,
  TwentyThree,
  TwentyFour,
  TwentyFive,
  TwentySix,
  TwentySeven,
  TwentyEight,
  TwentyNine,
  Thirty,
  ThirtyOne,
  ThirtyTwo,
  ThirtyThree,
  ThirtyFour,
  ThirtyFive,
  ThirtySix,
  ThirtySeven,
  ThirtyEight,
  ThirtyNine,
  Forty,
  FortyOne,
  FortyTwo,
  FortyThree,
  FortyFour,
  FortyFive,
  FortySix,
  FortySeven,
  FortyEight,
  FortyNine,
  Fifty,
  FiftyOne,
  FiftyTwo,
  FiftyThree,
  FiftyFour,
  FiftyFive,
  FiftySix,
  FiftySeven,
  FiftyEight,
  FiftyNine,
}

export enum Minute {
  Every = 'every',
  Zero = 0,
  One,
  Two,
  Three,
  Four,
  Five,
  Six,
  Seven,
  Eight,
  Nine,
  Ten,
  Eleven,
  Twelve,
  Thirteen,
  Fourteen,
  Fifteen,
  Sixteen,
  Seventeen,
  Eighteen,
  Nineteen,
  Twenty,
  TwentyOne,
  TwentyTwo,
  TwentyThree,
  TwentyFour,
  TwentyFive,
  TwentySix,
  TwentySeven,
  TwentyEight,
  TwentyNine,
  Thirty,
  ThirtyOne,
  ThirtyTwo,
  ThirtyThree,
  ThirtyFour,
  ThirtyFive,
  ThirtySix,
  ThirtySeven,
  ThirtyEight,
  ThirtyNine,
  Forty,
  FortyOne,
  FortyTwo,
  FortyThree,
  FortyFour,
  FortyFive,
  FortySix,
  FortySeven,
  FortyEight,
  FortyNine,
  Fifty,
  FiftyOne,
  FiftyTwo,
  FiftyThree,
  FiftyFour,
  FiftyFive,
  FiftySix,
  FiftySeven,
  FiftyEight,
  FiftyNine,
}

export enum Hour {
  Every = 'every',
  Midnight = 0,
  OneAM,
  TwoAM,
  ThreeAM,
  FourAM,
  FiveAM,
  SixAM,
  SevenAM,
  EightAM,
  NineAM,
  TenAM,
  ElevenAM,
  Noon,
  OnePM,
  TwoPM,
  ThreePM,
  FourPM,
  FivePM,
  SixPM,
  SevenPM,
  EightPM,
  NinePM,
  TenPM,
  ElevenPM,
}

export enum DayOfMonth {
  Every = 'every',
  One = 1,
  Two,
  Three,
  Four,
  Five,
  Six,
  Seven,
  Eight,
  Nine,
  Ten,
  Eleven,
  Twelve,
  Thirteen,
  Fourteen,
  Fifteen,
  Sixteen,
  Seventeen,
  Eighteen,
  Nineteen,
  Twenty,
  TwentyOne,
  TwentyTwo,
  TwentyThree,
  TwentyFour,
  TwentyFive,
  TwentySix,
  TwentySeven,
  TwentyEight,
  TwentyNine,
  Thirty,
  ThirtyOne,
}

export enum Month {
  Every = 'every',
  January = 1,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December,
}

export enum DayOfWeek {
  Mon = 0,
  Tue,
  Wed,
  Thu,
  Fri,
  Sat,
  Sun,
}

interface CronExpression {
  second: Second[] | '*' | '?';
  minute: Minute[] | '*' | '?';
  hour: Hour[] | '*' | '?';
  dayOfMonth: DayOfMonth[] | '*' | '?';
  month: Month[] | '*';
  dayOfWeek: DayOfWeek[] | '*' | '?';
  year?: number | '*' | '?';
}

export const WbCronExpressionInput = (props: any) => {
  const {
    onChange,
    title = 'Cron Expression',
    description = '',
    isHidden,
    value: formData,
  } = props;
  const getMainFrequency = () => {
    if (!formData) return Frequency.DAY;
    if (
      formData?.split(' ')[4] === '*' &&
      formData?.split(' ')[3] === '*' &&
      (formData?.split(' ')[5] === '*' || formData?.split(' ')[5] === '?')
    )
      return Frequency.DAY;
    if (formData?.split(' ')[4] === '*') return Frequency.MONTH;
    return Frequency.YEAR;
  };
  const [frequency, setFrequency] = useState<Frequency>(getMainFrequency());
  const [cronExpression, setCronExpression] = useState<CronExpression>({
    second: (formData?.split(' ')[0] ?? [Second.Zero]) as '*' | '?' | Second[],
    minute: (formData?.split(' ')[1] ?? [Minute.Zero]) as '*' | '?' | Minute[],
    hour: (formData?.split(' ')[2] ?? [Hour.Midnight]) as '*' | '?' | Hour[],
    dayOfMonth: (formData?.split(' ')[3] ?? [DayOfMonth.One]) as
      | '*'
      | '?'
      | DayOfMonth[],
    month: (formData?.split(' ')[4] ?? [Month.January]) as '*' | Month[],
    dayOfWeek: (formData?.split(' ')[5] ?? '?') as '*' | '?' | DayOfWeek[],
  });

  const showMonths = frequency < 2;
  const showDaysOfMonth = frequency < 3;
  const showDaysOfWeek = frequency < 4;
  const showHours = frequency < 5;
  const showMinutes = false;
  const showSeconds = false;

  const secondOptions = Object.values(Second)
    .filter(value => typeof value === 'number' || value === 'every')
    .map(e => e?.toString());
  const minuteOptions = Object.values(Minute)
    .filter(value => typeof value === 'number' || value === 'every')
    .map(e => e?.toString());
  const hourOptions = Object.values(Hour)
    .filter(value => typeof value === 'number' || value === 'every')
    .map(e => e?.toString());
  const dayOfMonthOptions = Object.values(DayOfMonth)
    .filter(value => typeof value === 'number' || value === 'every')
    .map(e => e?.toString());
  const monthOptions = Object.values(Month).filter(
    value => typeof value !== 'number',
  );
  const dayOfWeekOptions = Object.values(DayOfWeek).filter(
    value => typeof value !== 'number',
  );

  const handleSegmentChange = (segment: keyof CronExpression, e: any) => {
    let res: any;
    if (e.length === 0 || !e) {
      res = '?';
    }
    if ((e.length === 1 && e[0] === 'every') || e[e.length - 1] === 'every') {
      res = '*';
    } else {
      res = e?.filter((value: string) => value !== 'every');
    }
    if (segment === 'dayOfWeek' && !e.includes('every')) {
      res = e.filter(
        (value: string) => e.filter((v: string) => v === value).length === 1,
      );
    }
    setCronExpression(prev => ({
      ...prev,
      [segment]: res,
    }));
  };
  const handleEveryChange = (segment: keyof CronExpression, e: string) => {
    const temp = e === '1' ? '?' : `*/${e}`;
    setCronExpression(prev => ({
      ...prev,
      [segment]: e.length > 0 ? temp : '?',
    }));
  };

  const getValue = (e: any) => {
    if (!e) return [];
    if (Array.isArray(e)) return e;
    if (e === '?') return [];
    if (e === '*') return ['every'];
    if (e?.toString()?.startsWith('*/')) return ['every'];
    if (e.split(',').filter((value: string) => !!value))
      return e.split(',').filter((value: string) => !!value);
    return [];
  };
  const getFrequencyValue = (e: any) => {
    if (!e || Array.isArray(e)) return undefined;
    if (e === '*') return '1';
    if (e?.startsWith('*/') && !!e.split('*/')[1]) return e.split('*/')[1];
    return undefined;
  };

  const getFrequency = (value: string) => {
    switch (value) {
      case 'YEAR':
        return Frequency.YEAR;
      case 'MONTH':
        return Frequency.MONTH;
      case 'WEEK':
        return Frequency.WEEK;
      case 'DAY':
        return Frequency.DAY;
      case 'HOUR':
        return Frequency.HOUR;
      case 'MINUTE':
        return Frequency.MINUTE;
      case 'SECOND':
        return Frequency.SECOND;
      default:
        return Frequency.YEAR;
    }
  };

  const splitValue = (value: '*' | '?' | string | any[]) => {
    if (Array.isArray(value) && value.length === 0) return '?';
    return `${value}`;
  };
  const [showAdvanced, setShowAdvanced] = useState(true);
  const [cron, setCron] = useState(formData ?? '0 0 0 1 1 ?');
  useEffect(() => {
    const string = `${splitValue(cronExpression.second)} ${splitValue(
      cronExpression.minute,
    )} ${splitValue(cronExpression.hour)} ${splitValue(
      cronExpression.dayOfMonth,
    )} ${splitValue(cronExpression.month)} ${splitValue(
      cronExpression.dayOfWeek,
    )}`;
    setCron(string);
  }, [cronExpression, showAdvanced]);

  const resetCronExpression = () => {
    setCronExpression({
      second: (cron.split(' ')[0] ?? '*') as '*' | '?' | Second[],
      minute: (cron.split(' ')[1] ?? '*') as '*' | '?' | Minute[],
      hour: (cron.split(' ')[2] ?? '*') as '*' | '?' | Hour[],
      dayOfMonth: (cron.split(' ')[3] ?? '*') as '*' | '?' | DayOfMonth[],
      month: (cron.split(' ')[4] ?? '*') as '*' | Month[],
      dayOfWeek: (cron.split(' ')[5] ?? '*') as '*' | '?' | DayOfWeek[],
    });
  };

  const resetFrequency = (freq: Frequency) => {
    switch (freq) {
      case Frequency.MONTH:
        setCronExpression({
          second: [Second.Zero],
          minute: [Minute.Zero],
          hour: [Hour.Midnight],
          dayOfMonth: [DayOfMonth.One],
          month: '*',
          dayOfWeek: '?',
        });
        break;
      case Frequency.WEEK:
        setCronExpression({
          second: [Second.Zero],
          minute: [Minute.Zero],
          hour: [Hour.Midnight],
          dayOfMonth: '?',
          month: '*',
          dayOfWeek: [DayOfWeek.Mon],
        });
        break;

      case Frequency.DAY:
        setCronExpression({
          second: [Second.Zero],
          minute: [Minute.Zero],
          hour: [Hour.Midnight],
          dayOfMonth: '*',
          month: '*',
          dayOfWeek: '?',
        });
        break;
      case Frequency.HOUR:
        setCronExpression({
          second: [Second.Zero],
          minute: [Minute.Zero],
          hour: '*',
          dayOfMonth: '*',
          month: '*',
          dayOfWeek: '?',
        });
        break;
      case Frequency.MINUTE:
        setCronExpression({
          second: [Second.Zero],
          minute: '*',
          hour: '*',
          dayOfMonth: '*',
          month: '*',
          dayOfWeek: '?',
        });
        break;
      case Frequency.SECOND:
        setCronExpression({
          second: '*',
          minute: '*',
          hour: '*',
          dayOfMonth: '*',
          month: '*',
          dayOfWeek: '?',
        });
        break;
      case Frequency.YEAR:
      default:
        setCronExpression({
          second: [Second.Zero],
          minute: [Minute.Zero],
          hour: [Hour.Midnight],
          dayOfMonth: [DayOfMonth.One],
          month: [Month.January],
          dayOfWeek: '?',
        });
    }
  };
  useEffect(() => {
    onChange(cron);
  }, [cron, onChange]);

  useEffect(() => {
    resetFrequency(getMainFrequency());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();
  const [showSwitchModal, setShowSwitchModal] = useState(false);
  const theme = useTheme();
  return (
    <>
      <Box style={{ display: isHidden ? 'none' : undefined }}>
        <Box className={classes.title}>
          <Typography variant="h6">{title}</Typography>
          <Typography variant="body2">{description}</Typography>
        </Box>
        <WbWidget
          title="Set Frequency"
          actions={
            <Box style={{ display: 'flex', alignItems: 'center', gap: 15 }}>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <span>Textual input</span>
                <Tooltip
                  title={
                    !!getCronValidationMessage(cron).message
                      ? 'Cannot switch mode while there are errors'
                      : 'Switch between simplified and advanced mode'
                  }
                >
                  <span>
                    <Switch
                      checked={!showAdvanced}
                      onChange={() => {
                        if (showAdvanced) setShowAdvanced(false);
                        else setShowSwitchModal(true);
                      }}
                      disabled={!!getCronValidationMessage(cron).message}
                    />
                  </span>
                </Tooltip>
              </Box>
              <WbDivider orientation="vertical" />
              <WbCardActionButton
                label="Reset"
                onClick={() => {
                  resetFrequency(Frequency.DAY);
                }}
              />
            </Box>
          }
        >
          <WbDivider orientation="horizontal" />
          <Box
            style={{
              background: showAdvanced ? theme.palette.grey[100] : 'inherit',
            }}
          >
            <Box style={{ margin: '1rem' }}>
              <FormControl
                required
                style={{ width: '100%' }}
                error={!!getCronValidationMessage(cron).message}
              >
                <WbTextField
                  fullWidth
                  disabled={showAdvanced}
                  value={cron}
                  onChange={e => setCron(e.target.value)}
                  error={!!getCronValidationMessage(cron).message}
                  helperText={<>{getCronValidationMessage(cron).message}</>}
                />
              </FormControl>
            </Box>
            <WbDivider orientation="horizontal" />
          </Box>
          <WbCardContent>
            {showAdvanced && (
              <Box className={classes.gridContainer}>
                <Box className={classes.singleInputContainer}>
                  <WbSelect
                    value={Frequency[frequency]}
                    label="Every"
                    options={['YEAR', 'MONTH', 'WEEK', 'DAY', 'HOUR']}
                    onChange={e => {
                      const freq = getFrequency(e.target.value);
                      setFrequency(freq);
                      resetFrequency(freq);
                    }}
                  />
                </Box>
                {showMonths && (
                  <>
                    <FormControl required>
                      <WbMultiSelect
                        value={
                          getValue(cronExpression.month).map((el: string) => {
                            if (el === 'every') return el;
                            return Month[el as keyof typeof Month];
                          }) ?? []
                        }
                        label="Months"
                        options={monthOptions as string[]}
                        onChange={value =>
                          handleSegmentChange(
                            'month',
                            value.target.value?.map((el: string) => {
                              if (el === 'every') return el;
                              return Month[el as keyof typeof Month];
                            }),
                          )
                        }
                      />
                    </FormControl>
                    <FormControl required>
                      <WbSelect
                        value={getFrequencyValue(cronExpression.month)}
                        label="Monthly frequency"
                        disabled={
                          !getValue(cronExpression.month).includes('every')
                        }
                        options={Array.from({ length: 11 }, (_, i) =>
                          (i + 1)?.toString(),
                        )}
                        onChange={value =>
                          handleEveryChange('month', value.target.value)
                        }
                      />
                    </FormControl>
                  </>
                )}
                {showDaysOfMonth && (
                  <>
                    <FormControl required>
                      <WbMultiSelect
                        value={getValue(cronExpression.dayOfMonth)?.map(
                          (el: string | number) => el.toString(),
                        )}
                        label="Days of Month"
                        options={dayOfMonthOptions}
                        onChange={value =>
                          handleSegmentChange('dayOfMonth', value.target.value)
                        }
                      />
                    </FormControl>
                    <FormControl required>
                      <WbSelect
                        value={getFrequencyValue(cronExpression.dayOfMonth)}
                        label="Daily frequency"
                        disabled={
                          !getValue(cronExpression.dayOfMonth).includes('every')
                        }
                        options={Array.from({ length: 30 }, (_, i) =>
                          (i + 1)?.toString(),
                        )}
                        onChange={value =>
                          handleEveryChange('dayOfMonth', value.target.value)
                        }
                      />
                    </FormControl>
                  </>
                )}
                {showDaysOfWeek && (
                  <Box className={classes.singleInputContainer}>
                    <Box className={classes.daysOfWeek}>
                      <Box className={classes.checkboxContainer}>
                        {dayOfWeekOptions.map(value => (
                          <MenuItem
                            className={classes.checkboxListElement}
                            onClick={() =>
                              handleSegmentChange('dayOfWeek', [
                                ...getValue(cronExpression.dayOfWeek),
                                DayOfWeek[
                                  value as keyof typeof DayOfWeek
                                ]?.toString(),
                              ])
                            }
                          >
                            <Checkbox
                              size="small"
                              onChange={() =>
                                handleSegmentChange('dayOfWeek', [
                                  ...getValue(cronExpression.dayOfWeek),
                                  DayOfWeek[
                                    value as keyof typeof DayOfWeek
                                  ]?.toString(),
                                ])
                              }
                              checked={getValue(
                                cronExpression.dayOfWeek,
                              ).includes(
                                DayOfWeek[
                                  value as keyof typeof DayOfWeek
                                ]?.toString(),
                              )}
                            />
                            <ListItemText primary={value} />
                          </MenuItem>
                        ))}
                      </Box>
                      <RadioGroup
                        aria-labelledby="radio-connections"
                        name="radio-buttons-group"
                        onChange={(_, value) =>
                          handleSegmentChange('dayOfWeek', [value])
                        }
                      >
                        <FormControlLabel
                          checked={
                            getValue(cronExpression.dayOfWeek)[0] === 'every'
                          }
                          value="every"
                          control={<Radio size="small" />}
                          label="Every day"
                        />
                      </RadioGroup>
                    </Box>
                  </Box>
                )}
                {showHours && (
                  <>
                    <FormControl required>
                      <WbMultiSelect
                        value={getValue(cronExpression.hour)?.map(
                          (el: string | number) => el.toString(),
                        )}
                        label="Hours"
                        options={hourOptions}
                        onChange={value =>
                          handleSegmentChange('hour', value.target.value)
                        }
                      />
                    </FormControl>
                    <FormControl required>
                      <WbSelect
                        value={getFrequencyValue(cronExpression.hour)}
                        label="Hourly frequency"
                        disabled={
                          !getValue(cronExpression.hour).includes('every')
                        }
                        options={Array.from({ length: 23 }, (_, i) =>
                          (i + 1)?.toString(),
                        )}
                        onChange={value =>
                          handleEveryChange('hour', value.target.value)
                        }
                      />
                    </FormControl>
                  </>
                )}
                {showMinutes && (
                  <>
                    <FormControl required>
                      <WbMultiSelect
                        value={getValue(cronExpression.minute)?.map(
                          (el: string | number) => el.toString(),
                        )}
                        label="Minutes"
                        options={minuteOptions}
                        onChange={value =>
                          handleSegmentChange('minute', value.target.value)
                        }
                      />
                    </FormControl>
                    <FormControl required>
                      <WbSelect
                        value={getFrequencyValue(cronExpression.minute)}
                        label="Minutes frequency"
                        disabled={
                          !getValue(cronExpression.minute).includes('every')
                        }
                        options={Array.from({ length: 59 }, (_, i) =>
                          (i + 1)?.toString(),
                        )}
                        onChange={value =>
                          handleEveryChange('minute', value.target.value)
                        }
                      />
                    </FormControl>
                  </>
                )}
                {showSeconds && (
                  <Box className={classes.singleInputContainer}>
                    <FormControl required>
                      <WbMultiSelect
                        value={getValue(cronExpression.second)?.map(
                          (el: string | number) => el.toString(),
                        )}
                        label="Seconds"
                        options={secondOptions}
                        onChange={value =>
                          handleSegmentChange('second', value.target.value)
                        }
                      />
                    </FormControl>
                  </Box>
                )}
              </Box>
            )}
            <Box style={{ marginTop: '0.5rem' }}>
              <span>
                <b>Repeats: </b>
                {getCronResult(cron)}
              </span>
            </Box>
          </WbCardContent>
        </WbWidget>
      </Box>
      {showSwitchModal && (
        <ConfirmDialog
          title="Switch to simplified mode"
          description="By switching mode, there are some fields that might be aligned between the simplified view and the output string. Are you sure you want to switch?"
          open={showSwitchModal}
          onClose={() => {
            setShowSwitchModal(false);
          }}
          onConfirm={() => {
            setShowSwitchModal(false);
            setFrequency(getMainFrequency());
            resetCronExpression();
            setShowAdvanced(true);
          }}
          confirmButtonText="Yes, switch"
        />
      )}
    </>
  );
};
