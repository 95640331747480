import { WbCronExpressionInput } from '@agilelab/plugin-wb-platform';
import React from 'react';
import { isHidden } from '../../utils';

export const CronExpressionPicker = (props: any) => {
  const { formData, onChange, uiSchema, schema } = props;
  return (
    <WbCronExpressionInput
      value={formData}
      onChange={onChange}
      title={schema.title}
      description={schema.description}
      isHidden={isHidden(uiSchema)}
    />
  );
};
