import { WbCronExpressionInput } from '@agilelab/plugin-wb-platform';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

type Props = UseFormReturn<any> & {
  value: string | undefined;
};

export const CronExpressionInput: React.FC<Props> = props => {
  return (
    <Controller
      control={props.control}
      name="cronExpression"
      render={({ field }) => (
        <WbCronExpressionInput
          value={field.value}
          onChange={field.onChange}
          title="Cron Expression"
        />
      )}
    />
  );
};
