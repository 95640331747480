import {
  getTruncateMaxLinesStyle,
  WbBreadcrumb,
  WbBreadcrumbs,
} from '@agilelab/plugin-wb-platform';
import {
  Box,
  makeStyles,
  Tooltip,
  Typography,
  TypographyProps,
} from '@material-ui/core';
import React from 'react';
import { EntityIcon, ResultTitle } from '../../../Entity';
import { Row } from './utils';

const useStyles = makeStyles(
  () => ({
    nameRoot: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    },
    nameContainer: {
      display: 'flex',
      flexDirection: 'column',
    },

    descriptionText: getTruncateMaxLinesStyle(2),
    date: getTruncateMaxLinesStyle(1, false),
  }),
  { name: 'SearchResultsTableCells' },
);

type NameCellProps = {
  searchResult: Row;
};

export const NameCell = ({ searchResult }: NameCellProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.nameRoot}>
      <EntityIcon
        useCaseTemplateId={searchResult.useCaseTemplateId}
        kind={searchResult._computedInfo.kind}
        title={searchResult.title}
        style={{ flexShrink: 0 }}
      />
      <Box className={classes.nameContainer}>
        <ResultTitle
          concise
          title={searchResult._nameWithVersion}
          location={searchResult.location}
        />
        <WbBreadcrumbs variant="secondary" disableLastItemHighlight>
          {searchResult._resolvedDomains.map(d => (
            <WbBreadcrumb key={d}>{d}</WbBreadcrumb>
          ))}
          {searchResult._computedInfo.parent?.displayName && (
            <WbBreadcrumb>
              {searchResult._computedInfo.parent.displayName}
            </WbBreadcrumb>
          )}
          {searchResult._computedInfo.parent?.parentComponentDisplayName && (
            <WbBreadcrumb>
              {searchResult._computedInfo.parent.parentComponentDisplayName}
            </WbBreadcrumb>
          )}
        </WbBreadcrumbs>
      </Box>
    </Box>
  );
};

export const DescriptionCell = ({
  children,
  title,
  ...props
}: TypographyProps & { title: string }) => {
  const classes = useStyles();

  return (
    <Tooltip title={title}>
      <Typography className={classes.descriptionText} {...props}>
        {children}
      </Typography>
    </Tooltip>
  );
};

export const DateCell = ({
  date,
  ...props
}: TypographyProps & { date?: string }) => {
  const classes = useStyles();

  if (!date) return <></>;

  const parsedDate = new Date(date).toLocaleString();

  return (
    <Tooltip title={parsedDate}>
      <Typography className={classes.date} {...props}>
        {parsedDate}
      </Typography>
    </Tooltip>
  );
};
