import React from 'react';
import {
  CustomViewComponent,
  CustomViewPages,
  useCustomViewInfoFromConfig,
} from '@agilelab/plugin-wb-platform';

CustomViewPages.register({
  id: 'marketplace_component_general',
  function: function ComponentGeneral() {
    const defaultSideInfo = [
      <CustomViewComponent
        key="dp"
        type="string"
        value="{{ _system.name }}"
        colSpan="4"
        label="Parent System"
      />,
      <CustomViewComponent
        key="parentComponent"
        type="string"
        value="{{ _computedInfo.parentComponent[0].data.display_name }}"
        colSpan="4"
        label="Parent Component"
        showWhenHasValue
      />,
      <CustomViewComponent
        key="owner"
        type="string"
        value="{{ _system.owner_display_name }}"
        colSpan="4"
        label="Owner"
      />,
      <CustomViewComponent
        key="env"
        type="string"
        value="{{ _system.environment.name }}"
        colSpan="4"
        label="Environment"
      />,
    ];
    let sideInfo = useCustomViewInfoFromConfig(
      'mesh.marketplace.ui.outputPortPage.sideInfo',
      { showOnlyWithValues: true, defaults: { colSpan: '4' } },
    );

    sideInfo = sideInfo.length === 0 ? defaultSideInfo : sideInfo;

    const gridInfo = useCustomViewInfoFromConfig(
      'mesh.marketplace.ui.outputPortPage.gridInfo',
      { showOnlyWithValues: true },
    ).concat(
      useCustomViewInfoFromConfig(
        'mesh.marketplace.ui.outputPortPage.additionalGeneralInfo',
        { showOnlyWithValues: true },
      ),
    );

    return (
      <CustomViewComponent
        type="marketplace_info_card"
        title="General Information"
      >
        <CustomViewComponent type="hbox">
          <CustomViewComponent type="container" size={3}>
            <CustomViewComponent
              type="automatic_fields_list"
              path=""
              defaults={{ description: { colSpan: 4 }, id: { colSpan: 2 } }}
              order={[
                'name',
                'fullyQualifiedName',
                'version',
                'creationDate',
                'description',
              ]}
            />
            {gridInfo}
          </CustomViewComponent>
          {sideInfo.length > 0 && (
            <CustomViewComponent type="container" borderLeft>
              {sideInfo}
            </CustomViewComponent>
          )}
        </CustomViewComponent>
        <CustomViewComponent
          type="hline"
          showWhen={{
            value: '{{ tags.length }}',
            notEquals: 0,
          }}
        />
        <CustomViewComponent
          showWhen={{ value: '{{ tags.length }}', notEquals: 0 }}
          label="Tags"
          type="tags"
          path="tags"
        />
        <CustomViewComponent type="marketplace_flag_and_score" />
      </CustomViewComponent>
    );
  },
});
