import React from 'react';
import { AsyncEnumFilter } from '@agilelab/plugin-wb-platform';
import {
  GET_OUTPUTPORTTYPES,
  getOutportTypesBuildWhere,
} from '@agilelab/plugin-wb-marketplace-common';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { gql, useLazyQuery } from '@apollo/client';
import { anyCaseToCapitalCase } from '@agilelab/plugin-wb-platform-common';

export type ConsumableInterfaceTypeFilter = {
  label: string;
  rawFilters: string[];
};

type AsyncConsumableInterfaceTypeFilterProps = {
  systemInstanceId: number;
  value: ConsumableInterfaceTypeFilter[] | undefined;
  onChange: (value: ConsumableInterfaceTypeFilter[] | undefined) => void;
};

export const AsyncConsumableInterfaceTypeFilter = ({
  systemInstanceId,
  value,
  onChange,
}: AsyncConsumableInterfaceTypeFilterProps) => {
  const configApi = useApi(configApiRef);

  const consumableInterfaceTypeField = configApi.getString(
    'practiceShaper.migration.consumableInterfaceTypeField',
  );

  const [getOutputporttypeList] = useLazyQuery(gql(GET_OUTPUTPORTTYPES), {
    variables: {
      where: getOutportTypesBuildWhere(systemInstanceId),
      consumableInterfaceTypeField: `$.${consumableInterfaceTypeField}`,
    },
  });
  return (
    <AsyncEnumFilter<ConsumableInterfaceTypeFilter>
      field="Type"
      getOptions={() =>
        getOutputporttypeList().then(result => {
          // map where each key is a label (in capitalCase) and the value is the array of types that are different but have the exact label when converted in capitalCase
          const mergeMap: Record<string, string[]> = {};
          (
            result?.data?.marketplace_instances
              .filter(
                (x: any) =>
                  !!x.outputporttype && typeof x.outputporttype === 'string',
              )
              .map((x: any) => x.outputporttype) ?? []
          ).forEach((d: string) => {
            const label = anyCaseToCapitalCase(d, true);
            if (label in mergeMap) mergeMap[label].push(d);
            else {
              mergeMap[label] = [d];
            }
          });
          // each filter is a group of types that can be slightly different but have the same label when converted to capitalCase
          // only one entry per group will appear in the dropdown (shown as the capitalCase label)
          return Object.entries(mergeMap).map(([label, rawFilters]) => ({
            label,
            rawFilters: [...new Set(rawFilters)],
          }));
        })
      }
      onChange={onChange}
      value={value}
      renderOption={o => o.label}
      renderValue={o => {
        return o.label;
      }}
      onSearch={(v, options) =>
        options.filter(o => new RegExp(v, 'ig').test(o.label))
      }
    />
  );
};
