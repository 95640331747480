import {
  ACLRequest,
  AccessStatusEnum,
  EnumFilter,
  RequestStatusEnum,
  TableCellProps,
  WbCardContent,
  WbPagination,
  WbTable,
  WbTableFilters,
  WbWidget,
  getObjectPropFromString,
  getRequestLabel,
} from '@agilelab/plugin-wb-platform';
import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import { ACLByDP } from './DataAccessTab';
import { TableBody } from '@material-ui/core';
import { Row } from './AccessControlRowByDP';
import {
  ACLInfoByOp,
  ACLSystemOwned,
  RequestAndACLsByOp,
} from '@agilelab/plugin-wb-access-control-common';
import { ComponentInstanceEntities } from '@agilelab/plugin-wb-marketplace-common';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import {
  AsyncConsumableInterfaceTypeFilter,
  ConsumableInterfaceTypeFilter,
  GET_COMPONENT_BY_ID,
} from '@agilelab/plugin-wb-marketplace';

interface AccessControlListProps {
  selectedRow?: ACLSystemOwned;
}
interface ACLFilters {
  type?: ConsumableInterfaceTypeFilter[];
  access?: string[];
  status?: string[];
}

/**
 * - top-level components — including subcomponents without their parent in the list — are sorted alphabetically
 * - subcomponents come immediately after their parent
 * - subcomponents with the same parent are sorted alphabetically
 */
const sortItems = (a: ACLByDP, b: ACLByDP) => {
  const itemId = (item: { id?: number; displayName: string }) =>
    `${item.displayName}_${item.id ?? -1}`;

  const groupId = (item: ACLByDP) =>
    item.parent ? itemId(item.parent) : itemId(item);

  const aGroupId = groupId(a);
  const bGroupId = groupId(b);

  if (aGroupId === bGroupId) {
    const aId = itemId(a);
    const bId = itemId(b);
    if (aId === aGroupId) return -1;
    if (bId === aGroupId) return 1;
    return aId.localeCompare(bId);
  }

  return aGroupId.localeCompare(bGroupId);
};

// TODO: This component is very similar to AccessControlListByRef component in the Marketplace plugin. Define a common component to be reused in both plugins.
export const AccessControlList: React.FC<AccessControlListProps> = ({
  selectedRow,
}: AccessControlListProps) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [ACL, setACL] = useState<ACLByDP[]>([]);
  const [tableRows, setTableRows] = useState<ACLByDP[]>([]);
  const [search, setSearch] = useState<string>('');
  const [filters, setFilters] = React.useState<ACLFilters>({});
  const [getOp] = useLazyQuery<ComponentInstanceEntities>(GET_COMPONENT_BY_ID);
  const configApi = useApi(configApiRef);
  const consumableInterfaceTypeField = configApi.getString(
    'practiceShaper.migration.consumableInterfaceTypeField',
  );
  useEffect(() => {
    if (!selectedRow) setTableRows([]);
  }, [selectedRow, setTableRows]);

  const columns = useMemo<TableCellProps<ACLByDP>[]>(
    () => [
      {
        field: 'displayName',
        cellProps: {
          size: 'medium',
          align: 'left',
          width: 'auto',
        },
        headerName: 'Name',
      },
      {
        field: 'type',
        cellProps: {
          size: 'medium',
          align: 'left',
          width: 'auto',
        },
        headerName: 'type',
      },
      {
        field: 'access',
        cellProps: {
          size: 'medium',
          align: 'left',
          width: '30%',
        },
        headerName: 'access',
      },
      {
        field: 'status',
        cellProps: {
          size: 'medium',
          align: 'left',
          width: 'auto',
        },
        headerName: 'Request status',
      },
    ],
    [],
  );

  const getAccessField = (aclInfo: ACLInfoByOp[]) => {
    return aclInfo.reduce((acc, curr) => {
      acc.groups = acc.groups || [];

      if (
        curr.ref.includes('user') &&
        curr.ACL &&
        !!Object.keys(curr.ACL).length
      )
        acc.user = true;
      if (
        curr.ref.includes('group') &&
        curr.ACL &&
        !!Object.keys(curr.ACL).length
      ) {
        acc.groups.push(curr.ref);
      }
      return acc;
    }, {} as { user?: boolean; groups?: string[] });
  };

  useEffect(() => {
    const fetchData = async (op: RequestAndACLsByOp) => {
      const res = await getOp({
        variables: {
          id: op.outputPortId,
          consumableInterfaceTypeField,
        },
      });
      const OP = res.data?.instances.at(0);
      const displayName = OP?.displayName ?? op.outputPortName ?? '';
      const parentComponent = OP?.parentComponent?.at(0)?.data;
      const parent = parentComponent
        ? {
            id: parentComponent.id,
            displayName:
              parentComponent.display_name ?? parentComponent.external_id,
          }
        : undefined;

      return {
        id: OP?.id ?? -1,
        type: OP?.outputporttype ?? '-',
        parent,
        displayName,
        access: op.aclInfo?.every(
          acl => !acl.ACL || Object.keys(acl.ACL)?.length === 0,
        )
          ? AccessStatusEnum.Disabled
          : AccessStatusEnum.Provided,
        refs: getAccessField(op.aclInfo),
        status: op.aclInfo?.some(x => x.ACLRequest?.length > 0)
          ? RequestStatusEnum.OTHER
          : undefined,
        requests: op.aclInfo.reduce((acc, curr) => {
          if (curr.ACLRequest) {
            const requests = curr.ACLRequest?.map(r => ({
              ...r,
              ref: curr.ref,
              createdBy: r.requesterDisplayName ?? '',
              status: getRequestLabel(r),
            }));

            acc.push(...requests);
          }
          return acc;
        }, [] as ACLRequest[]),
      };
    };

    const updateACL = async () => {
      if (selectedRow) {
        const data = await Promise.all(
          selectedRow?.outputPorts?.map(fetchData),
        );
        setACL(data);
      }
    };

    updateACL();
  }, [selectedRow, getOp, consumableInterfaceTypeField]);

  useMemo(() => {
    const fields = columns.map(col => col.field || '');
    const filtered = ACL?.filter(row => {
      const rowItems = fields.map(field =>
        getObjectPropFromString(row, field.toString()),
      );
      if (row.parent) rowItems.push(row.parent.displayName); // search by parent display name
      return rowItems.find(
        item => item && item?.toLowerCase().includes(search.toLowerCase()),
      );
    });
    Object.keys(filters).forEach(key =>
      filters[key as keyof ACLFilters] === undefined
        ? delete filters[key as keyof ACLFilters]
        : {},
    );

    let filteredRows = filtered;
    // TODO: improve code quality and clarity
    Object.keys(filters).forEach(key => {
      const filterKey = key as keyof ACLFilters;
      let filterValue: string[] | undefined;

      if (filterKey === 'type')
        filterValue = filters.type?.flatMap(k => k.rawFilters);
      else filterValue = filters[filterKey];

      if (filterValue === undefined) return;

      filteredRows = filteredRows?.filter(row =>
        (filterValue ?? []).includes(
          String(row[key as keyof Pick<ACLByDP, 'kind' | 'access'>]),
        ),
      );
    });
    setTableRows((filteredRows ?? []).sort(sortItems));
  }, [ACL, filters, search, columns]);

  const body = (rows: ACLByDP[]) =>
    !!rows.length ? (
      <TableBody>
        {rows.map((row, index) => (
          <Row key={`table-row-${index}`} row={row} />
        ))}
      </TableBody>
    ) : undefined;

  return (
    <WbWidget
      cardStyle={{ height: '100%' }}
      headerStyle={{ overflow: 'none' }}
      title={`${selectedRow?.systemDisplayName ?? ''} Consumable Interfaces`}
      footer={
        <WbPagination
          count={tableRows.length ?? 0}
          offset={page * rowsPerPage}
          limit={rowsPerPage}
          onPageChange={newPage => {
            setPage(newPage);
          }}
          onRowsPerPageChange={newRowsPerPage => {
            setRowsPerPage(newRowsPerPage);
          }}
          style={{
            alignSelf: 'flex-end',
            backgroundColor: 'white',
            borderTop: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        />
      }
    >
      <WbCardContent style={{ padding: '0px', height: '100%' }}>
        <WbTableFilters
          style={{ padding: '12px 16px' }}
          onClear={() => {
            setFilters({});
          }}
          searchValue={search}
          onSearch={(value: string) => {
            setPage(0);
            setSearch(value);
          }}
        >
          <AsyncConsumableInterfaceTypeFilter
            systemInstanceId={selectedRow?.systemId ?? -1}
            onChange={type => setFilters({ ...filters, type: type })}
            value={filters.type}
          />
          <EnumFilter<string>
            field="Access"
            options={[AccessStatusEnum.Disabled, AccessStatusEnum.Provided]}
            onChange={accessFilter =>
              setFilters({ ...filters, access: accessFilter })
            }
            value={filters.access}
            renderOption={o => o}
            renderValue={o => o}
            onSearch={v =>
              [AccessStatusEnum.Disabled, AccessStatusEnum.Provided].filter(o =>
                new RegExp(v, 'ig').test(o),
              )
            }
          />
        </WbTableFilters>
        <WbTable<ACLByDP>
          styles={{
            container: {
              maxHeight: '500px',
              overflow: 'auto',
            },
            header: { position: 'sticky', top: 0, zIndex: 1 },
          }}
          components={{
            tableContent: {
              body: body(
                tableRows?.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage,
                ) || [],
              ),
              columns,
            },
          }}
        />{' '}
      </WbCardContent>
    </WbWidget>
  );
};
