import { useApi } from '@backstage/core-plugin-api';
import { CatalogApi, catalogApiRef } from '@backstage/plugin-catalog-react';
import React, { useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import {
  Column,
  GenericEntityType,
  GenericProviderType,
  getLabelAndValue,
  KindEnum,
  NotFoundException,
  Pagination,
  Provider,
  selectTemplateEntity,
  TemplateEntity,
} from '@agilelab/plugin-wb-platform';
import { parseEntityRef, ResourceEntity } from '@backstage/catalog-model';
import { contextProviderFactory } from '../entityProvider.factory';
import { ResourceFilters, ResourceFiltersSection } from './ResourceFilters';
import { transformUrnToWitboostId } from '@agilelab/plugin-wb-builder-common';
import { useColumns } from '../hooks';

type ResourceTypeEntity = ResourceEntity & GenericEntityType;

interface ResourceContextType
  extends GenericProviderType<ResourceTypeEntity[], ResourceFilters> {}

const [useResourceContext, ResourceContextProvider] =
  contextProviderFactory<ResourceContextType>();

export default useResourceContext;

export interface ResourceProviderProps {
  templateKind: TemplateEntity | undefined;
  children: React.ReactNode;
}

const defaultColumns = new Map<string, string>([
  ['name', '{{metadata.name}}'],
  ['description', '{{metadata.description}}'],
]);

const toResourceTypeEntity = (resource: ResourceEntity): ResourceTypeEntity => {
  return {
    ...resource,
    __metadata: { kind: KindEnum.resource, name: resource.metadata.name },
  };
};

export const ResourceProvider: React.FC<ResourceProviderProps> = props => {
  const { children, templateKind } = props;
  const catalogApi = useApi(catalogApiRef);
  const [nextCursor, setNextCursor] = useState<string | undefined>(undefined);
  const [prevCursor, setPrevCursor] = useState<string | undefined>(undefined);
  const [pagination, setPagination] = useState<Pagination>({
    limit: 5,
    currentPage: 0,
  });
  const [count, setCount] = useState<number>(0);
  const [filters, setFilters] = useState<ResourceFilters>({});

  const columns: Column[] = useColumns(defaultColumns, templateKind);

  const ResourcesState = useAsync(async () => {
    const result = await catalogApi.queryEntities({
      filter: {
        kind: ['Resource'],
      },
      limit: pagination.limit,
      cursor: pagination.cursor,
      fullTextFilter: {
        term: filters.search!,
        fields: ['metadata.name'],
      },
    });
    setNextCursor(result.pageInfo.nextCursor);
    setPrevCursor(result.pageInfo.prevCursor);
    setCount(result.totalItems);
    return result.items.map(i => toResourceTypeEntity(i as ResourceEntity));
  }, [templateKind, filters, pagination.limit, pagination.cursor]);

  return (
    <ResourceContextProvider
      value={{
        entitiesState: ResourcesState,
        columns,
        filters,
        changeFilters: <K extends keyof ResourceFilters>(
          key: K,
          filterValue: ResourceFilters[K],
        ) => {
          setPagination(p => ({ ...p, currentPage: 0, cursor: undefined }));
          setFilters(f => ({ ...f, [key]: filterValue }));
        },
        resetFilters: () => {
          setFilters({});
        },
        templateKind,
        pagination,
        setPagination,
        count,
        nextCursor,
        prevCursor,
      }}
    >
      {children}
    </ResourceContextProvider>
  );
};

export class ResourceHandlerProvider
  implements Provider<ResourceTypeEntity[], ResourceFilters>
{
  private readonly catalogApi: CatalogApi;
  private readonly availableKinds: TemplateEntity[];

  constructor(catalogApi: CatalogApi, availableKinds: TemplateEntity[]) {
    this.catalogApi = catalogApi;
    this.availableKinds = availableKinds;
  }

  useContext = useResourceContext;

  renderContextProvider(
    templateKind: TemplateEntity | undefined,
    children: React.ReactNode,
  ) {
    return (
      <ResourceProvider templateKind={templateKind}>
        {children}
      </ResourceProvider>
    );
  }

  filtersSection = ResourceFiltersSection;

  async resolve(ref: string) {
    const resolvedRef = transformUrnToWitboostId(ref) || ref;
    const parsedEntityRef = parseEntityRef(resolvedRef);
    try {
      if (parsedEntityRef.kind === 'resource') {
        const entity = await this.catalogApi.getEntityByRef(resolvedRef);
        if (entity)
          return getLabelAndValue(
            toResourceTypeEntity(entity as ResourceEntity),
            selectTemplateEntity(this.availableKinds, 'Resource'),
          );

        throw new NotFoundException(
          `Cannot find a Resource entity with ref: ${ref}`,
        );
      }

      return undefined;
    } catch (error) {
      throw new Error(`Error while resolve entity with ref: ${ref}`);
    }
  }
}
