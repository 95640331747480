import { Entity, stringifyEntityRef } from '@backstage/catalog-model';

export type DataContractSubcomponent = {
  name: string;
  __dataContractEnabled?: boolean;
  dataContract?: unknown;
  __parentEntityRef: string;
  // in the future we may introduce nested subcomponents
  components?: DataContractSubcomponent[];
};

export type DataContractComponent = Entity & {
  spec?: {
    components?: DataContractSubcomponent[];
    mesh?: {
      __dataContractEnabled?: boolean;
      dataContract?: unknown;
      name: string;
    };
  };
};

export type DataContractEntity =
  | DataContractComponent
  | DataContractSubcomponent;

/**
 * Collects all the ids of the components and subcomponents in the descriptor, that are data contracts.
 * @example [{ id: '1', components: [{ id: '2' }] }] => ['1', '2']
 * @param components
 */
export function collectDataContractsIds(
  components: Record<string, any>[],
): string[] {
  return components.flatMap(component => {
    const subcomponentIds = component.components
      ? collectDataContractsIds(component.components)
      : [];
    return component.__dataContractEnabled && component.id
      ? [component.id, ...subcomponentIds]
      : subcomponentIds;
  });
}

/**
 * Collects all the data contracts in the catalog-info of the component.
 * This is used when the descriptor is not available.
 */
export function collectDataContractsFromComponent(
  component: DataContractComponent,
): DataContractEntity[] {
  const dataContracts: DataContractEntity[] = [];

  if (component.spec?.mesh?.__dataContractEnabled)
    dataContracts.push(component);

  const subcomponents = component.spec?.components;
  if (subcomponents) {
    subcomponents.forEach(subcomponent => {
      if (subcomponent.__dataContractEnabled) {
        dataContracts.push({
          ...subcomponent,
          __parentEntityRef: stringifyEntityRef(component),
        });
      }

      dataContracts.push(
        ...collectDataContractsFromComponent({
          ...(subcomponent as unknown as DataContractComponent),
          spec: { components: subcomponent.components },
        } as DataContractComponent),
      );
    });
  }

  return dataContracts;
}

/**
 * Collects all the guardian components in the descriptor.
 * @param components
 */
export function collectGuardians(
  components: Record<string, any>[],
): Record<string, any>[] {
  return components.flatMap(component => {
    const subcomponents = component.components
      ? collectGuardians(component.components)
      : [];
    const dataContractSpec =
      component.__dataContractGuardianSpec ??
      component.descriptor?.__dataContractGuardianSpec;
    return dataContractSpec ? [component, ...subcomponents] : subcomponents;
  });
}
