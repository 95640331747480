import { Theme } from '@material-ui/core';

export const getTaxonomyColor = (
  taxonomyId: string,
  theme: Theme,
  fallbackColor?: string,
) =>
  theme.palette.taxonomies[taxonomyId] ??
  fallbackColor ??
  theme.palette.blueGrey[200];
