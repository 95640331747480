import { z } from 'zod';

import {
  Context,
  Engine,
  InteractionType,
  ResultType,
  Severity,
  Strategy,
  Timing,
  Trigger,
} from '../../enums';

export const PolicyCreateRequest = z.object({
  name: z.string().min(1),
  description: z.string().optional(),
  context: z.nativeEnum(Context),
  strategy: z.nativeEnum(Strategy),
  engine: z.nativeEnum(Engine),
  resourceType: z.array(z.string()),
  selectors: z.array(
    z.object({
      description: z.string(),
      path: z.string(),
      values: z.array(z.string()),
    }),
  ),
  tags: z.array(z.string()),
  externalSpecific: z
    .object({
      tresholds: z.array(
        z.object({
          range: z.object({
            min: z.number(),
            max: z.number(),
          }),
          severity: z.nativeEnum(Severity),
          message: z.string().optional(),
        }),
      ),
    })
    .optional(),
  externalUrl: z.string().nullable(),
  timing: z.nativeEnum(Timing),
  resultType: z.nativeEnum(ResultType),
  interactionType: z.nativeEnum(InteractionType),
  environments: z.array(z.string()),
  cronExpression: z.string().optional(),
  preprocessing: z.array(z.string()).optional(),
  additionalMetadata: z.string(),
  trigger: z.nativeEnum(Trigger),
  cueScript: z.string().nullable(),
  severity: z.nativeEnum(Severity),
  governanceAgentSpec: z
    .object({
      url: z
        .string({
          required_error: 'Url is required',
        })
        .url({ message: 'Invalid url' }),

      prompt: z
        .string({
          required_error: 'Content is required',
        })
        .min(10, 'Min Length 10 characters')
        .max(5000, 'Max Length 5000 characters'),
    })
    .nullable(),
});

export type PolicyCreate = z.infer<typeof PolicyCreateRequest>;
