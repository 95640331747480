import React, { useState } from 'react';
import { WbCard, WbCardContent, WbTabs } from '@agilelab/plugin-wb-platform';
import { GovernanceTestOverviewInfo } from '../GovernanceTestOverviewInfo';
import {
  Engine,
  EvaluationResultSpecific,
  Policy,
} from '@agilelab/plugin-wb-governance-common';
import { Box } from '@material-ui/core';
import { GovernanceTestOverviewPolicyInfo } from './GovernanceTestOverviewPolicyInfo';
import { PolicyCueScriptEditor } from '../../../PolicyDetail';
import { GovernanceTestOverviewPolicyDetails } from './GovernanceTestOverviewPolicyDetails';
import { PolicyWittyGovernanceAgentEditor } from '../../../PolicyDetail/PolicyWittyGovernanceAgentEditor';

interface Props {
  result: EvaluationResultSpecific;
  policy: Policy;
}

enum Tab {
  Result,
  Entity,
}

export const GovernanceTestOverviewPolicyTabs: React.FC<Props> = props => {
  const [selectedTab, setSelectedTab] = useState<Tab>(Tab.Result);
  return (
    <WbCard
      cardStyle={{ height: '100%' }}
      title="Overview"
      toolsComponents={
        <WbTabs
          tabs={[
            {
              name: 'Result',
              action: () => {
                setSelectedTab(Tab.Result);
              },
              selected: selectedTab === Tab.Result,
            },
            {
              name: 'Policy',
              action: () => {
                setSelectedTab(Tab.Entity);
              },
              selected: selectedTab === Tab.Entity,
            },
          ]}
        />
      }
    >
      <WbCardContent>
        {selectedTab === Tab.Result ? (
          <Box
            style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
          >
            <GovernanceTestOverviewInfo {...props} />
            <GovernanceTestOverviewPolicyDetails
              policy={props.policy}
              test={{
                errors: props.result.result?.errors || [],
                content: props.result.resource_content,
                name:
                  props.result.resource_display_name ||
                  props.result.resource.external_id,
              }}
            />
          </Box>
        ) : (
          <>
            <GovernanceTestOverviewPolicyInfo {...props} />

            {props.policy.engine === Engine.Cue && (
              <Box mt={2}>
                <PolicyCueScriptEditor
                  cueScript={props.policy.cueSpecific.cueScript}
                  editable={false}
                />
              </Box>
            )}
            {props.policy.engine === Engine.GovernanceAgent && (
              <Box mt={2}>
                <PolicyWittyGovernanceAgentEditor
                  policyContent={props.policy.governanceAgentSpec.prompt}
                  editable={false}
                />
              </Box>
            )}
          </>
        )}
      </WbCardContent>
    </WbCard>
  );
};
