import { z } from 'zod';
import { compactStringRef } from '@agilelab/plugin-wb-platform-common';

export const ACLObjectZod = z.object({
  // It would be possible to make a strict check, like this:
  // refs: z.preprocess(
  //   value => compactStringRef(value as string),
  //   z.string().regex(new RegExp('^(user|group):.+$')),
  // )
  // at the moment, however, we want to support a reference that is not in the format `kind/namespace:name`.
  refs: z.string(),
  request_id: z.string().nullable(),
  port_id: z.preprocess(Number, z.number()),
  locked: z.boolean(),
});

export enum AclVerb {
  Grant = 'grant',
  Revoke = 'revoke',
}
export const AclVerbZod = z
  .string()
  .refine(
    data => Object.values(AclVerb).includes(data as AclVerb),
    `Acl Verb must be equal to one of these values: ${Object.values(AclVerb)}`,
  );
export enum AclStatus {
  Running = 'RUNNING',
  Pending = 'PENDING',
  Waiting = 'WAITING',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Terminated = 'TERMINATED',
  Provisioning = 'PROVISIONING',
}
const AclStatusZod = z
  .string()
  .refine(
    data => Object.values(AclStatus).includes(data as AclStatus),
    `Acl Status must be equal to one of these values: ${Object.values(
      AclStatus,
    )}`,
  );

export const ACLRequestObjectZod = z.object({
  requester: z.preprocess(
    value => compactStringRef(value as string),
    z.string().regex(new RegExp('^user:.+$')),
  ),
  requester_display_name: z.string(),
  // It would be possible to make a strict check, like this:
  // refs: z.preprocess(
  //   value => compactStringRef(value as string),
  //   z.string().regex(new RegExp('^(user|group):.+$')),
  // )
  // at the moment, however, we want to support a reference that is not in the format `kind/namespace:name`.
  refs: z.string(),
  request_id: z.string(),
  port_id: z.preprocess(Number, z.number()),
  resource_name: z.string(),
  response_details: z.any().nullable(),
  status: AclStatusZod,
  verb: AclVerbZod,
  environment: z.string(),
  external_id: z.string(),
});

export const ACLPostRequestZod = z.object({
  objects: ACLObjectZod.array(),
});

export const ACLSystemsOwnedPostRequestZod = z.object({
  refs: z.array(z.string()),
  environment: z.string(),
});

export const ACLRequestPostRequestZod = z.object({
  objects: ACLRequestObjectZod.array(),
});

const aclRequest = z.object({
  id: z.number(),
  requestId: z.string(),
  status: AclStatusZod,
  verb: AclVerbZod,
  requesterDisplayName: z.string(),
  createdAt: z.date(),
});

const acl = z.object({
  requestId: z.string().nullable(),
  locked: z.boolean(),
  createdAt: z.date(),
});

const aclInfoByOpZod = z.object({
  ref: z.string(),
  ACL: acl.nullable(),
  ACLRequest: z.array(aclRequest),
});

const aclInfoByRefZod = z.object({
  outputPortId: z.number(),
  outputPortName: z.string().optional(),
  systemOwnerId: z.number().optional(),
  systemOwnerDisplayName: z.string().optional(),
  ACL: acl.nullable(),
  ACLRequest: z.array(aclRequest),
});

export const RequestAndACLsByOpZod = z.object({
  outputPortId: z.number(),
  domain: z.string(),
  systemVersion: z.string(),
  outputPortName: z.string().optional(),
  systemOwnerId: z.number(),
  systemOwnerDisplayName: z.string().optional(),
  aclInfo: z.array(aclInfoByOpZod),
});

export const ACLSystemOwnedZod = z.object({
  systemDisplayName: z.string(),
  systemId: z.number(),
  systemVersion: z.string(),
  domain: z.string(),
  outputPorts: z.array(RequestAndACLsByOpZod),
});

export const ACLSystemOwnedZodArray = z.array(ACLSystemOwnedZod);

export const RequestAndACLsByRefZod = z.object({
  ref: z.string(),
  aclInfo: z.array(aclInfoByRefZod),
});

export const GetACLBySystemUrnResponseZod = z
  .object({
    userRef: z.string(),
    idOutputPort: z.number(),
  })
  .array();

export type ACLObject = z.infer<typeof ACLObjectZod>;
export type ACLRequestObject = z.infer<typeof ACLRequestObjectZod>;
export type RequestAndACLsByOp = z.infer<typeof RequestAndACLsByOpZod>;
export type RequestAndACLsByRef = z.infer<typeof RequestAndACLsByRefZod>;
export type ACLSystemOwned = z.infer<typeof ACLSystemOwnedZod>;
export type ACLInfoByOp = z.infer<typeof aclInfoByOpZod>;
export type GetACLBySystemUrnResponse = z.infer<
  typeof GetACLBySystemUrnResponseZod
>;
export type ACLResource = {
  id: number;
  name: string;
};
