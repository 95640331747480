import {
  transformUrnToWitboostId,
  WitboostDomain,
} from '@agilelab/plugin-wb-builder-common';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { CatalogApi, catalogApiRef } from '@backstage/plugin-catalog-react';
import React, { useCallback, useEffect, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { contextProviderFactory } from '../entityProvider.factory';
import {
  Column,
  GenericEntityType,
  GenericProviderType,
  getLabelAndValue,
  KindEnum,
  NotFoundException,
  Pagination,
  Provider,
  selectTemplateEntity,
  TemplateEntity,
} from '@agilelab/plugin-wb-platform';
import { DomainFilters, DomainFiltersSection } from './DomainFilters';
import {
  INSTANCE_OF,
  PART_OF,
} from '@agilelab/plugin-wb-practice-shaper-common';
import { parseEntityRef } from '@backstage/catalog-model';
import { expandEntityRefSafe } from '@agilelab/plugin-wb-platform-common';
import { useColumns } from '../hooks';

type DomainTypeEntity = WitboostDomain & GenericEntityType;

interface DomainContextType
  extends GenericProviderType<DomainTypeEntity[], DomainFilters> {}

const [useDomainContext, DomainContextProvider] =
  contextProviderFactory<DomainContextType>();

export default useDomainContext;

export interface DomainProviderProps {
  templateKind: TemplateEntity | undefined;
  children: React.ReactNode;
  parentTemplateRef?: string;
}

const defaultColumns = new Map<string, string>([
  [
    'name',
    '{% if spec.mesh.name %}{{spec.mesh.name}}{% else %}{{metadata.name}}{% endif %}',
  ],
  ['description', '{{metadata.description}}'],
]);

const toDomainTypeEntity = (domain: WitboostDomain): DomainTypeEntity => {
  return {
    ...domain,
    __metadata: { kind: KindEnum.domain, name: domain.metadata.name },
  };
};

export const DomainProvider: React.FC<DomainProviderProps> = props => {
  const { children, templateKind, parentTemplateRef } = props;
  const catalogApi = useApi(catalogApiRef);
  const identityApi = useApi(identityApiRef);
  const [nextCursor, setNextCursor] = useState<string | undefined>(undefined);
  const [prevCursor, setPrevCursor] = useState<string | undefined>(undefined);
  const [pagination, setPagination] = useState<Pagination>({
    limit: 5,
    currentPage: 0,
  });
  const [count, setCount] = useState<number>(0);
  const [filters, setFilters] = useState<DomainFilters>({});
  const preFilters = templateKind?.filter;
  const [instanceOfDomainType, setInstanceOfDomainType] = useState<string[]>(
    [],
  );

  const columns: Column[] = useColumns(defaultColumns, templateKind);

  // Function to fetch domain types based on the project reference
  const getDomainTypesFromProjectRef = useCallback(
    async (projectRef: string) => {
      const { token } = await identityApi.getCredentials();
      const projectType = await catalogApi.getEntityByRef(projectRef, {
        token,
      });

      if (!projectType) return undefined;

      if (projectType.kind.toLocaleLowerCase() === 'systemtype') {
        const domainTypeRelation = projectType.relations?.find(
          rel => rel.type === PART_OF,
        );
        return domainTypeRelation ? [domainTypeRelation.targetRef] : [];
      }

      if (projectType.kind.toLocaleLowerCase() === 'componenttype') {
        const systemParent = await catalogApi.getEntityByRef(
          projectType.spec?.partOfSystem as string,
          { token },
        );
        const domainTypeRelation = systemParent?.relations?.find(
          rel => rel.type === PART_OF,
        );
        return domainTypeRelation ? [domainTypeRelation.targetRef] : [];
      }

      return undefined;
    },
    [catalogApi, identityApi],
  );

  // Function to fetch domain type compatible with the current template
  const fetchDomainTypeCompatibleWithCurrentTemplate = useCallback(async () => {
    const { token } = await identityApi.getCredentials();
    if (!parentTemplateRef) return undefined;

    const parent = await catalogApi.getEntityByRef(parentTemplateRef, {
      token,
    });
    if (!parent || !parent.spec?.generates) return undefined;

    return getDomainTypesFromProjectRef(parent.spec.generates as string);
  }, [
    catalogApi,
    getDomainTypesFromProjectRef,
    identityApi,
    parentTemplateRef,
  ]);

  // Function to fetch domain types compatible with specific types
  const fetchDomainTypesCompatibleWithTypes = useCallback(
    async (projectRefs: string[]) => {
      const results = await Promise.allSettled(
        projectRefs.map(projectRef => getDomainTypesFromProjectRef(projectRef)),
      );
      return results
        .filter(result => result.status === 'fulfilled' && result.value)
        .flatMap(result => (result as PromiseFulfilledResult<string[]>).value);
    },
    [getDomainTypesFromProjectRef],
  );

  useEffect(() => {
    const getDomainTypes = async () => {
      let domainTypes: string[] = [];

      const kind = preFilters?.practiceShaper?.kind;

      if (kind === 'compatibleWithCurrentTemplate') {
        domainTypes =
          (await fetchDomainTypeCompatibleWithCurrentTemplate()) || [];
      }

      if (
        kind === 'compatibleWithType' &&
        preFilters?.practiceShaper?.compatibleWithType &&
        Array.isArray(preFilters.practiceShaper.compatibleWithType)
      ) {
        domainTypes = await fetchDomainTypesCompatibleWithTypes(
          preFilters?.practiceShaper?.compatibleWithType,
        );
      }

      if (
        kind === 'instanceOf' &&
        preFilters?.practiceShaper?.instanceOf &&
        Array.isArray(preFilters.practiceShaper.instanceOf)
      ) {
        domainTypes = preFilters.practiceShaper.instanceOf.map(
          (instanceOfEl: string) =>
            expandEntityRefSafe(instanceOfEl) ?? instanceOfEl,
        );
      }

      setInstanceOfDomainType(domainTypes);
    };

    getDomainTypes();
  }, [
    catalogApi,
    fetchDomainTypeCompatibleWithCurrentTemplate,
    fetchDomainTypesCompatibleWithTypes,
    identityApi,
    parentTemplateRef,
    preFilters,
  ]);

  const domainsState = useAsync(async () => {
    const result = await catalogApi.queryEntities({
      filter: {
        kind: ['Domain'],
        [`relations.${INSTANCE_OF}`]: instanceOfDomainType,
      },
      limit: pagination.limit,
      cursor: pagination.cursor,
      fullTextFilter: {
        term: filters.search!,
        fields: ['spec.mesh.name', 'metadata.name'],
      },
    });
    setNextCursor(result.pageInfo.nextCursor);
    setPrevCursor(result.pageInfo.prevCursor);
    setCount(result.totalItems);
    return result.items.map(i => toDomainTypeEntity(i as WitboostDomain));
  }, [
    templateKind,
    filters,
    instanceOfDomainType,
    pagination.limit,
    pagination.cursor,
  ]);

  return (
    <DomainContextProvider
      value={{
        entitiesState: domainsState,
        columns,
        filters,
        changeFilters: <K extends keyof DomainFilters>(
          key: K,
          filterValue: DomainFilters[K],
        ) => {
          setPagination(p => ({ ...p, currentPage: 0, cursor: undefined }));
          setFilters(f => ({ ...f, [key]: filterValue }));
        },
        resetFilters: () => {
          setFilters({});
        },
        templateKind,
        pagination,
        setPagination,
        count,
        nextCursor,
        prevCursor,
      }}
    >
      {children}
    </DomainContextProvider>
  );
};

export class DomainHandlerProvider
  implements Provider<DomainTypeEntity[], DomainFilters>
{
  private readonly catalogApi: CatalogApi;
  private readonly availableKinds: TemplateEntity[];

  constructor(catalogApi: CatalogApi, availableKinds: TemplateEntity[]) {
    this.catalogApi = catalogApi;
    this.availableKinds = availableKinds;
  }

  useContext = useDomainContext;

  renderContextProvider(
    templateKind: TemplateEntity | undefined,
    children: React.ReactNode,
    parentTemplateRef?: string,
  ) {
    return (
      <DomainProvider
        templateKind={templateKind}
        parentTemplateRef={parentTemplateRef}
      >
        {children}
      </DomainProvider>
    );
  }

  filtersSection = DomainFiltersSection;

  async resolve(ref: string) {
    const resolvedRef = transformUrnToWitboostId(ref) || ref;
    const parsedEntityRef = parseEntityRef(resolvedRef);
    try {
      if (parsedEntityRef.kind === 'domain') {
        const entity = await this.catalogApi.getEntityByRef(resolvedRef);
        if (entity)
          return getLabelAndValue(
            toDomainTypeEntity(entity as WitboostDomain),
            selectTemplateEntity(this.availableKinds, 'Domain'),
          );

        throw new NotFoundException(
          `Cannot find a Domain entity with ref: ${ref}`,
        );
      }

      return undefined;
    } catch (error) {
      throw new Error(`Error while resolve entity with ref: ${ref}`);
    }
  }
}
